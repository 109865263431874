import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  IWSLCommunicator,
  IWSLDevice,
  IWSLDeviceExecution,
  IWSLDeviceModel,
  IWSLDeviceSensor,
  IWSLDeviceSensorFilter,
  IWSLDeviceSetCommunicator
} from 'wsl-device';
import {IWSLObject} from '@app/object/models/object';
import {IWSLRoom} from '@app/room/models/room';
import {IWSLAsyncErrors, IWSLNavMenu, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';
import {IWSLDeviceConf} from '@app/device-conf/models/device-conf';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';
import {IWSLRoomFilter} from '@app/room/models/room-filter';
import {IWSLDeviceConfGroupFilter} from '@app/device-conf-group/models/device-conf-group-filter';
import {IWSLDeviceInputV, IWSLDeviceSchemaV} from '@app/device/components/add-device-setup-metering-node/add-device-setup-metering-node.component';
import {IWSLDeviceConfFilter} from '@app/device-conf/models/device-conf-filter';
import {IWSLDataConfFilter} from '@app/device-conf-ext/models/data-conf-filter';
import {IWSLActionButton, WSLMaterializeHelper} from 'wsl-shared';
import {IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexFilter} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {IWSLCommunicatorFilter} from '@app/communicator/models/communicator-filter';
import {IWSLServiceProcedure} from '@app/service-procedure/models/service-procedure';
import {MenuHelperService} from '@core/services/menu-helper.service';
import {DeviceModelService} from '@app/device/services/device-model.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@env/environment';
import {AllResources, IWSLResource, IWSLResourceUnit, IWSLResourceValue, IWSLResourceVariable, UserProfileService} from 'wsl-ek-core';
import {DeviceActionButtonType, HideDeviceButtonType} from '@app/device/enums/device-action-button';
import {DeviceExecutionService} from '@app/device/services/device-execution.service';
import {IWSLUser} from 'wsl-user';
import {DeviceSensorService} from '@app/device-sensor/services/device-sensor.service';
import {combineLatest, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';

enum AddDeviceStage {
  readonly = -1,
  selectPlacementType = 1,
  selectLocation = 2,
  barcodeInput = 3,
  selectCommunicator = 4,
  setupMeteringNode = 5,
 // selectConfiguration = 6,
  inputServiceOperation,
  activeServiceProcedure
}

@Component({
  selector: 'wsl-add-device-guide',
  templateUrl: './add-device-guide.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceGuideComponent implements OnInit, OnChanges {
  @Input() users: IWSLUser[];
  @Input() device: IWSLDevice;
  @Input() devicePending: boolean;
  @Input() deviceErrors: IWSLAsyncErrors;
  @Input() deviceObject: IWSLObject;
  @Input() deviceRoom: IWSLRoom;

  @Input() objects: IWSLObject[];
  @Input() objectPending: boolean;
  @Input() objectErrors: IWSLAsyncErrors;

  @Input() rooms: IWSLRoom[];
  @Input() roomPending: boolean;
  @Input() roomErrors: IWSLAsyncErrors;

  @Input() communicators: IWSLCommunicator[];
  @Input() communicatorPending: boolean;
  @Input() communicatorErrors: IWSLAsyncErrors;

  @Input() deviceSensors: IWSLDeviceSensor[];
  @Input() deviceSensorPending: boolean;
  @Input() deviceSensorErrors: IWSLAsyncErrors;

  @Input() confGroups: IWSLDeviceConfGroup[];
  @Input() confGroupPending: boolean;
  @Input() confGroupErrors: IWSLAsyncErrors;

  @Input() confs: IWSLDeviceConf[];
  @Input() confPending: boolean;
  @Input() confErrors: IWSLAsyncErrors;

  @Input() confData: IWSLDataConf[];
  @Input() confDataPending: boolean;
  @Input() confDataErrors: IWSLAsyncErrors;

  @Input() groupFileIndex: IWSLDeviceConfGroupFileIndex[];
  @Input() groupFileIndexPending: boolean;
  @Input() groupFileIndexErrors: IWSLAsyncErrors;

  @Input() serviceProcedures: IWSLServiceProcedure[];

  @Input() disabledDevice = true;
  @Input() disabledRoom = true;
  @Input() disabledCommunicator = true;
  @Input() disabledSensor = true;
  @Input() disabledServiceProcedure = true;

  @Output() loadObjects = new EventEmitter<null>();
  @Output() loadObject = new EventEmitter<number>();

  @Output() loadRooms = new EventEmitter<IWSLRoomFilter>();
  @Output() loadRoom = new EventEmitter<number>();
  @Output() clearRooms = new EventEmitter<null>();
  @Output() addRoom = new EventEmitter<IWSLRoom>();

  @Output() addDevice = new EventEmitter<IWSLDevice>();
  @Output() setDeviceCommunicator = new EventEmitter<IWSLDeviceSetCommunicator>();
  @Output() setDeviceConfGroup = new EventEmitter<IWSLDevice>();
  @Output() inputDevice = new EventEmitter<IWSLDevice>();
  @Output() repairDevice = new EventEmitter<IWSLDevice>();
  @Output() correctDevice = new EventEmitter<IWSLDevice>();
  @Output() verifyDevice = new EventEmitter<IWSLDevice>();
  @Output() reviseDevice = new EventEmitter<IWSLDevice>();
  @Output() outputDevice = new EventEmitter<IWSLDevice>();
  @Output() renameDevice = new EventEmitter<IWSLDevice>();
  @Output() hideDevice = new EventEmitter<IWSLDevice>();

  @Output() loadCommunicators = new EventEmitter<IWSLCommunicatorFilter>();
  @Output() addCommunicator = new EventEmitter<IWSLCommunicator>();

  @Output() loadDeviceSensors = new EventEmitter<IWSLDeviceSensorFilter>();
  @Output() updateDeviceSensors = new EventEmitter<IWSLDeviceSensor[]>();
 // @Output() upsertDeviceSensors = new EventEmitter<IWSLDeviceSensor[]>();
 // @Output() deleteDeviceSensors = new EventEmitter<number[]>();

  @Output() loadConfGroups = new EventEmitter<IWSLDeviceConfGroupFilter>();
  @Output() loadConfGroup = new EventEmitter<number>();
  @Output() loadConfs = new EventEmitter<IWSLDeviceConfFilter>();
  @Output() loadConfData = new EventEmitter<IWSLDataConfFilter>();
  @Output() loadFileIndexes = new EventEmitter<IWSLDeviceConfGroupFileIndexFilter>();
  @Output() loadFileIndex = new EventEmitter<number>();

  dexecution: IWSLDeviceExecution;
  visibleStage = 1;
  stageType = AddDeviceStage;
  inputs: IWSLDeviceInputV[] = [];
  schema: IWSLDeviceSchemaV;
  activeServiceProcedure: IWSLServiceProcedure;
  navMenu: IWSLNavMenu[] = [];
  actionButtons: IWSLActionButton[] = [];
  showCorrectionBtn = false;

  isHome = environment.home;

  private dmodels: IWSLDeviceModel[] = [];
  private dexecutions: IWSLDeviceExecution[] = [];
  private rvariables: IWSLResourceVariable[] = [];
  private rvalues: IWSLResourceValue[] = [];
  private runits: IWSLResourceUnit[] = [];
  private filledInputs: IWSLDeviceInputV[] = null;

  constructor(private route: ActivatedRoute,
              private menuService: MenuHelperService,
              private userProfileService: UserProfileService,
              private sensorService: DeviceSensorService,
              private chr: ChangeDetectorRef) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.dexecutions = data.dexecutions;
    this.rvariables = data.resourceVariables;
    this.rvalues = data.resourceValues;
    this.runits = data.resourceUnits;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device) {
      this.defineDevice();
      this.defineStage();
      this.deviceActionsAndNavs();
    }
    if (changes.deviceSensors) {
      this.deviceActionsAndNavs();
      this.fixFilledInputs();
    }
    if (changes.serviceProcedures) {
      this.checkProcedures();
    }
  }

  get canReturnToEditCommunicator() {
    return this.device && this.device.communicators && this.device.communicators.length > 0 && !this.device.communicators[0].network.on_board;
  }

  private defineStage() {
    if (!this.device) {
      if (environment.home) {
        this.device = {individual: true, room_id: this.userProfileService.getRoomID()};
        this.visibleStage = AddDeviceStage.barcodeInput;
        return;
      }
      this.visibleStage = AddDeviceStage.selectPlacementType;
      return;
    }
    if (!!this.device.group_id) {
      this.visibleStage = AddDeviceStage.inputServiceOperation;
      this.loadConfGroup.emit(this.device.group_id);
      if (!this.device.individual && !!this.device.group_file_id) {
        this.loadFileIndex.emit(this.device.group_file_id);
      }
    } else if (!this.device.communicators) {
      this.visibleStage = AddDeviceStage.selectCommunicator;
      this.loadCommunicators.emit({on_board: false, object_id: this.device.object_id});
    } else {
      this.visibleStage = AddDeviceStage.setupMeteringNode;
      this.loadConfGroups.emit({
        dmodel_ids: [this.device.dmodel_id],
        resource_ids: this.inputs.filter(i => !!i.resource_id).map(i => i.resource_id),
        protocols: this.device.communicators.map(c => c.vr)
      });
    }
    if (this.device.object_id) {
      this.loadObject.emit(this.device.object_id);
    }
    if (this.device.room_id) {
      this.loadRoom.emit(this.device.room_id);
    }
    this.loadDeviceSensors.emit({device_id: this.device.id});
    if (!!this.device.date_input) {
      this.visibleStage = 0;
    }
    if (!!this.disabledDevice) {
      this.visibleStage = AddDeviceStage.readonly;
    }
    this.checkProcedures();
  }

  onSelectPlacementType(ipu: boolean) {
    this.device = {individual: ipu};
    this.visibleStage = AddDeviceStage.selectLocation;
    this.loadObjects.emit();
  }

  onAddRoom(room: IWSLRoom) {
    this.addRoom.emit(room);
  }

  onSelectLocation({object_id, room_id}: {object_id: number, room_id: number}) {
    const object = this.objects ? this.objects.find(o => o.id === object_id) : null;
    const room = this.rooms ? this.rooms.find(r => r.id === room_id) : null;
    this.device = {
      ...this.device,
      object_id,
      object_name: object ? object.name : '',
      room_id,
      room_name: room ? room.name : ''
    };
    this.visibleStage = AddDeviceStage.barcodeInput;
  }

  onBarcodeInput(barcode: string) {
    this.device = {...this.device, barcode};
    this.addDevice.emit(this.device);
  }

  onSelectSchema(schema: IWSLDeviceSchemaV) {
    this.schema = {...schema};
  }
  onSetupMeteringNode({inputs, schema}: {inputs: IWSLDeviceInputV[], schema: IWSLDeviceSchemaV}) {
    this.inputs = [...inputs];
  }

  async onSelectConfGroup({group, fileIndex}: {group: IWSLDeviceConfGroup, fileIndex: IWSLDeviceConfGroupFileIndex}) {
    try {
      const deleted = await this.deleteSensors();
      const upserted = await this.upsertSensors(this.confData.filter(c => group.dconf_ids.includes(c.dconf_id)));
      let sensors = [];
      if (deleted || upserted) {
        sensors = await this.sensorService
          .getMany({device_id: this.device.id})
          .pipe(take(1), map(res => res.items), catchError(err => of([])))
          .toPromise();
      }
      this.inputs = [];
      if (!this.device.group_id || this.device.group_id !== group.id ||
        (fileIndex ? this.device.group_file_id !== fileIndex.id : !!this.device.group_file_id)) {
        this.setDeviceConfGroup.emit({...this.device, group_id: group.id, group_file_id: fileIndex ? fileIndex.id : null});
      } else {
        this.visibleStage = AddDeviceStage.inputServiceOperation;
        this.chr.markForCheck();
      }
      if (deleted || upserted) {
        this.updateDeviceSensors.emit(sensors);
      }
    } catch {}
  }

  onBack() {
    /*if (this.visibleStage === AddDeviceStage.selectConfiguration) {
      // @todo not allowed!
      // this.updateDevice.emit({...this.device, group_id: null});
      if (this.filledInputs && this.filledInputs.length > 0) {
        this.inputs = [];
        this.filledInputs = [];
      }
      this.visibleStage--;
    } else*/
    if (this.visibleStage === AddDeviceStage.setupMeteringNode) {
      if (this.canReturnToEditCommunicator) {
        this.visibleStage--;
      }
    } else if (this.visibleStage === AddDeviceStage.inputServiceOperation) {
      this.loadConfGroups.emit({
        dmodel_ids: [this.device.dmodel_id],
        protocols: this.device.communicators.map(c => c.vr)
      });
      if (this.inputs.length === 0) {
        if (this.filledInputs && this.filledInputs.length > 0) {
          this.inputs = [...this.filledInputs];
        } else if (this.deviceSensors) {
          this.inputs = this.deviceSensors.map(s => ({
            sensor_id: s.id,
            resource_id: s.resource_id,
            resource: AllResources.find(r => r.id === s.resource_id),
            resource_inx: s.resource_inx,
            rvalue_id: s.rvalue_id,
            rvalue: this.rvalues.find(rv => rv.id === s.rvalue_id),
            rvariable_id: this.rvalues.find(rv => rv.id === s.rvalue_id).rvariable_id,
            rvariable: this.rvariables.find(rv => rv.id === this.rvalues.find(rval => rval.id === s.rvalue_id).rvariable_id),
            unit: this.runits.find(unit => unit.rvariable_id === this.rvalues.find(rv => rv.id === s.rvalue_id).rvariable_id),
            entry: s.entry,
            serial_num: s.serial_num,
            date_next: s.date_next,
            impulse_weight: s.impulse_weight,
            device_id: s.device_id,
            dconf_ext_id: s.dconf_ext_id
          }));
        }
      }
      this.visibleStage--;
    }
  }

  onAddCommunicator(communicator: IWSLCommunicator) {
    this.addCommunicator.emit(communicator);
  }

  onSelectCommunicator({communicator_id, modbus_addr}: {communicator_id: number, modbus_addr: string}) {
    this.setDeviceCommunicator.emit({id: this.device.id, communicator_ids: [communicator_id], modbus_addr});
  }

  onChangeModbus(modbus_addr: string) {
    this.setDeviceCommunicator.emit({id: this.device.id, communicator_ids: this.device.communicator_ids, modbus_addr});
  }

  onInputDevice() {
    if (!!this.device && !!this.device.id && !!this.device.communicators && !!this.device.group_id) {
      if (this.serviceProcedures && this.serviceProcedures.length > 0 && this.serviceProcedures.some(p => !p.close && !p.cancel)) {
        this.visibleStage = AddDeviceStage.activeServiceProcedure;
        return;
      }
      const deviceConfGroup = this.confGroups.find(g => g.id === this.device.group_id);
      const externalResources = this.dexecution.resources.filter(r => !this.dexecution.resources_in.includes(r));
      const confGroupResources = !!deviceConfGroup.ext.resources ?
        deviceConfGroup.ext.resources.filter(r => externalResources.includes(r.resource_id)) : [];
      const dmodel = this.dmodels.find(m => m.id === this.device.dmodel_id);
      const isCalc = DeviceModelService.isCalc(dmodel);
      if (!isCalc) {
        if (!!deviceConfGroup.ext.resources && !this.deviceSensors && confGroupResources.length > 0) {
          WSLMaterializeHelper.toast({html: 'Не заданы входы для выбранной конфигурации'});
          return;
        }
        if (!deviceConfGroup.ext.resources && !!this.deviceSensors && this.deviceSensors.length > 0) {
          WSLMaterializeHelper.toast({html: 'Заданы входы лишние для выбранной конфигурации'});
          return;
        }
        if (!!deviceConfGroup.ext.resources && !!this.deviceSensors && confGroupResources.length !== this.deviceSensors.length) {
          WSLMaterializeHelper.toast({html: 'Количество ресурсов конфигурации не соответствуют количеству ресурсов заведенных входов'});
          return;
        }
        if (!!deviceConfGroup.ext.resources && !!this.deviceSensors &&
          WSLUtils.difference(this.deviceSensors.map(s => s.resource_id), confGroupResources.map(r => r.resource_id)).length !== 0) {
          WSLMaterializeHelper.toast({html: 'Ресурсы конфигурации не соответствуют ресурсам заведенных входов'});
          return;
        }
      }
      if (this.deviceSensors.some(i => !!this.deviceSensors.find(j => j.serial_num !== i.serial_num && i.entry === j.entry))) {
        WSLMaterializeHelper.toast({html: 'Датчики должны быть подключены на разные входы'});
        return;
      }
      this.inputDevice.emit(this.device);
    }
  }

  onRepairDevice() {
    this.repairDevice.emit(this.device);
  }

  onCorrectDevice() {
    this.correctDevice.emit(this.device);
  }

  onVerifyDevice() {
    this.verifyDevice.emit(this.device);
  }

  onReviseDevice() {
    this.reviseDevice.emit(this.device);
  }

  onOutputDevice() {
    this.outputDevice.emit(this.device);
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  private checkProcedures() {
    if (this.serviceProcedures && this.serviceProcedures.length > 0) {
      if (this.serviceProcedures.some(p => !p.close && !p.cancel)) {
        this.visibleStage = AddDeviceStage.activeServiceProcedure;
        this.activeServiceProcedure = this.serviceProcedures.find(p => !p.close && !p.cancel);
      }
      this.deviceActionsAndNavs();
    }
  }

  private deviceActionsAndNavs() {
    this.actionButtons = [];
    this.showCorrectionBtn = false;
    this.navMenu = [];
    if (!!this.device && this.serviceProcedures && this.serviceProcedures.length > 0) {
      this.navMenu = this.menuService.getDeviceNav(this.device);

      this.showCorrectionBtn = !!this.device.date_input && !this.device.date_output &&
        !DeviceModelService.isCalc(DeviceModelService.getModel(this.device, this.dmodels)) &&
        this.deviceSensors && this.deviceSensors.length > 0 && this.device.communicators.every(c => parseFloat(c.vr) >= 2.0);
    }
    if (!!this.device && !this.device.date_input && !!this.device.group_id && !this.activeServiceProcedure && !this.disabledDevice) {
      this.actionButtons.push({...HideDeviceButtonType});
    }
    this.chr.markForCheck();
  }

  onActionButtonClick(btn: number) {
    if (btn === DeviceActionButtonType.hide_device) {
      this.hideDevice.emit(this.device);
    }
  }

  onVisibleAddCommunicator(visible: boolean) {
    if (visible) {
      this.actionButtons = this.actionButtons.map(b => ({...b, visible: false}));
    } else {
      this.actionButtons = this.actionButtons.map(b => ({...b, visible: true}));
    }
  }

  private defineDevice() {
    this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
  }

  private fixFilledInputs() {
    if (this.deviceSensors && this.deviceSensors.length > 0 && this.filledInputs && this.filledInputs.length > 0) {
      this.filledInputs = this.filledInputs.map(input => {
        const sensor = this.deviceSensors.find(s => s.serial_num === input.serial_num);
        if (sensor && !input.sensor_id) {
          return {
            ...input,
            sensor_id: sensor.id
          };
        }
        return  input;
      });
    }
  }

  private async deleteSensors() {
    const deleteSensors = DeviceSensorService.defineDeleteSensors(this.inputs, this.deviceSensors);
    if (deleteSensors.length > 0) {
      if (deleteSensors.length > 0) {
        return await combineLatest(deleteSensors.map(id => this.sensorService.delete(id).pipe(take(1))))
          .pipe(take(1), map(res => true), catchError(err => of(false)))
          .toPromise();
      }
    }
    return false;
  }

  private async upsertSensors(confs: IWSLDataConf[]) {
    const {unknownSensors, upsertSensors} = DeviceSensorService.defineUpsertSensors(this.inputs, this.deviceSensors, confs);
    if (unknownSensors.length > 0) {
      WSLMaterializeHelper.toast({
        html:
          `Невозможно однозначно опередилить связь ${unknownSensors.length > 1 ? 'датчиков' : 'датчика'}:
           ${unknownSensors.map(s => s.serial_num)} и конфигурации ПУ.
         Свяжитесь с техподдержкой`
      });
      throw new Error('invalid sensors or configuration');
    }
    if (upsertSensors.length > 0) {
      return await this.sensorService.upsertMany(upsertSensors)
        .pipe(take(1), map(res => true), catchError(err => of(false)))
        .toPromise();
    }
    return false;
  }
}
