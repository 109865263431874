import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {IWSLDeviceInputV} from '@app/device/components/add-device-setup-metering-node/add-device-setup-metering-node.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IWSLAsyncErrors, MomentDateFormat, MomentDBDateFormat, WSLFormHelper, WSLFormValidators, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {WSLMaterializeHelper} from 'wsl-shared';
import * as moment from 'moment';
import {AllResources, IWSLResourceUnit, IWSLResourceValue, IWSLResourceVariable, ResourceVariableType} from 'wsl-ek-core';
import {ActivatedRoute} from '@angular/router';
import {IWSLDeviceSensor} from 'wsl-device';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'wsl-device-input-setup-form',
  templateUrl: './device-input-setup-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceInputSetupFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prefix = WSLUtils.randomID();
  @Input() input: IWSLDeviceInputV;
  @Input() pending: boolean;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() availableSensors: IWSLDeviceSensor[];
  @Input() canEditEntry: boolean;
  @Input() disabled = true;
  @Input() showValue = false;
  @Input() changeOnlyValue = false;
  @Output() save = new EventEmitter<IWSLDeviceInputV>();
  @Output() delete = new EventEmitter<IWSLDeviceInputV>();
  @Output() cancel = new EventEmitter();

  form = new FormGroup({
    id: new FormControl(null),
    resource_id: new FormControl({value: null, disabled: true}, [Validators.required]),
    resource_inx: new FormControl(1, [Validators.required]),
    rvalue_id: new FormControl(null, [Validators.required]),
    entry: new FormControl(null, [Validators.required]),
    sensor_id: new FormControl(null),
    // rvariable_id: new FormControl(null)
    serial_num: new FormControl(null, [Validators.required]),
    date_next: new FormControl(null, [Validators.required]),
    value_beg: new FormControl(null),
    impulse_weight: new FormControl(null)
  });

  entries = Array.from(new Array(20), (val, index) => ({id: index + 1, name: index + 1}));
  resourceInxs = Array.from(new Array(3), (val, index) => ({id: index + 1, name: index + 1}));
  weights = [{id: '0.1', name: 0.1}, {id: '1', name: 1}, {id: '10', name: 10}, {id: '100', name: 100}];
  maxDate = moment().add(15, 'years').format(MomentDateFormat);
  minDate = moment().subtract(5, 'years').format(MomentDateFormat);
  resources = [];
  showImpulseWeight = false;
  sensorsVisible = false;
  selectedSensor: IWSLDeviceSensor = null;
  availableRvalues = [];
  runit: IWSLResourceUnit;

  private rvariables: IWSLResourceVariable[] = [];
  private rvalues: IWSLResourceValue[] = [];
  private runits: IWSLResourceUnit[] = [];
  private MDropdown;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private chr: ChangeDetectorRef) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.rvariables = data.resourceVariables;
    this.rvalues = data.resourceValues;
    this.runits = data.resourceUnits;
    this.fillForm();
    this.form.get('rvalue_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(val => {
        const rval = this.rvalues.find(rv => rv.id === val);
        this.runit = rval ? this.runits.find(ru => ru.rvariable_id === rval.rvariable_id && ru.multiplier === '1') : null;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input) {
      this.fillForm();
    }
    if (changes.canEditEntry) {
      if (this.canEditEntry && !this.disabled) {
        this.form.get('entry').enable();
      } else if (!!this.form.get('entry').value) {
        this.form.get('entry').disable();
      }
    }
    if (changes.disabled) {
      if (this.disabled) {
        this.form.disable();
      }
    }
  }

  ngOnDestroy() {
    if (this.MDropdown) {
      this.MDropdown.close();
      this.MDropdown.destroy();
    }
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSave() {
    if (this.disabled) {
      WSLMaterializeHelper.toast({html: 'Доступ запрещен'});
      return;
    }
    if (this.form.valid) {
      this.save.emit({
        ...this.input,
        ...this.form.getRawValue(),
        date_next: moment(this.form.get('date_next').value, MomentDateFormat).format(MomentDBDateFormat)
      });
    } else {
      if (this.form.get('serial_num').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите серийный номер'});
      } else if (this.form.get('date_next').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите дату поверки'});
      } else if (this.form.get('impulse_weight').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите вес импульса'});
      } else if (this.form.get('entry').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите выход'});
      } else if (this.form.get('resource_id').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите ресурс'});
      } else if (this.form.get('resource_inx').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите номер ресурса'});
      } else if (this.form.get('rvalue_id').invalid) {
        WSLMaterializeHelper.toast({html: 'Укажите параметр'});
      }
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  onDelete() {
    this.delete.emit(this.input);
  }

  private fillForm() {
    this.form.reset();
    WSLFormHelper.fillForm(this.form, this.input);
    this.resources = AllResources.filter(r => r.id === this.input.resource_id);

    this.availableRvalues = this.rvalues
      .filter(r => r.resource_id === this.input.resource_id && r.rvariable_id === this.input.rvariable.id)
      .sort((a, b) => a.sort < b.sort ? -1 : 1);

    const rval = this.rvalues.find(rv => rv.id === this.input.rvalue_id);
    this.runit = rval ? this.runits.find(ru => ru.rvariable_id === rval.rvariable_id && ru.multiplier === '1') : null;

    if (!this.input.rvalue_id && this.availableRvalues.length === 1) {
      this.form.get('rvalue_id').setValue(this.availableRvalues[0].id);
    }
    this.showImpulseWeight = [ResourceVariableType.q, ResourceVariableType.v, ResourceVariableType.e].includes(this.input.rvariable.id);

    if (this.showImpulseWeight) {
      this.form.get('impulse_weight').setValidators([Validators.required]);
      if (this.form.get('impulse_weight').value === null && this.input.rvariable.id === ResourceVariableType.v) {
        this.form.get('impulse_weight').setValue(this.weights[1].id, {emitEvent: false});
      }
    } else {
      this.form.get('impulse_weight').clearValidators();
    }
    this.form.get('impulse_weight').updateValueAndValidity();
    /*if (!!this.input.sensor_id) {
      this.form.get('serial_num').disable();
    } else {
      this.form.get('serial_num').enable();
    }*/
    if (this.input.date_next) {
      this.form.get('date_next').setValue(moment(this.input.date_next, MomentDBDateFormat).format(MomentDateFormat), {emitEvent: false});
    }
    if (this.input.requiredValue) {
      this.form.get('value_beg').setValidators([Validators.required, WSLFormValidators.inputMeteringsValidator]);
    }
    if (this.changeOnlyValue) {
      this.form.disable();
      if (!this.disabled) {
        this.form.get('value_beg').enable();
      }
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  onSelectResource(resource_id: number) {
    this.form.get('resource_id').setValue(resource_id);
    this.form.get('resource_id').markAsDirty();
  }


  onShowSensors() {
    if (!!this.availableSensors && this.availableSensors.length > 0) {
      this.sensorsVisible = true;
      this.selectedSensor = null;
      if (!this.MDropdown) {
        this.initDropdown();
      }
      this.MDropdown.open();
    }
  }

  onHideSensors() {
    this.sensorsVisible = false;
    this.MDropdown.close();
  }

  trackById(index: number, obj) {
    return obj.id;
  }

  onSelectSensor(sensor: IWSLDeviceSensor) {
    this.selectedSensor = {...sensor};
  }

  onFillSensor() {
    if (!!this.selectedSensor) {
      this.form.get('sensor_id').setValue(this.selectedSensor.id);
      this.form.get('serial_num').setValue(this.selectedSensor.serial_num);
      if (this.selectedSensor.impulse_weight) {
        this.form.get('impulse_weight').setValue(this.selectedSensor.impulse_weight);
      }
      if (this.selectedSensor.date_next) {
        this.form.get('date_next').setValue(this.selectedSensor.date_next);
      }
      if (this.selectedSensor.rvalue_id && this.availableRvalues.map(r => r.id).includes(this.selectedSensor.rvalue_id)) {
        this.form.get('rvalue_id').setValue(this.selectedSensor.rvalue_id);
      }
      this.availableSensors = this.availableSensors.filter(s => s.id !== this.selectedSensor.id);
    }
    this.onHideSensors();
  }

  private initDropdown() {
    const self = this;
    this.MDropdown = WSLMaterializeHelper.initDropdown(`.${this.prefix}_dropdown_trigger`, {
      constrainWidth: false,
      coverTrigger: false,
      closeOnClick: false,
      onCloseEnd: function() {
        self.sensorsVisible = false;
        self.chr.markForCheck();
        //  self.showVariants = false;
        //  self.chr.markForCheck();
      }
    });
  }
}
