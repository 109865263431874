export enum LoraNodeClass {
  a = 1,
  b = 2,
  c = 3
}

export const LoraNodeClasses = [
  {id: LoraNodeClass.a, name: 'A'},
  {id: LoraNodeClass.b, name: 'B'},
  {id: LoraNodeClass.c, name: 'C'},
];
