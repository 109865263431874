import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IWSLDevice, IWSLDeviceExecution, IWSLDeviceModel, IWSLDeviceSensor} from 'wsl-device';
import {ActivatedRoute} from '@angular/router';
import {WSLRouterHelperService} from 'wsl-core';
import {IWSLObject} from '@app/object/models/object';
import {IWSLRoom} from '@app/room/models/room';
import {DeviceExecutionService} from '@app/device/services/device-execution.service';
import {DeviceModelService} from '@app/device/services/device-model.service';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';
import {IWSLDeviceConfGroupFileIndex} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {AllResources} from 'wsl-ek-core';
import {KaratLoraProtocolService} from '@core/services/karat-lora-protocol.service';
import {IWSLDeviceConf} from '@app/device-conf/models/device-conf';
import {WSLMaterializeHelper} from 'wsl-shared';

@Component({
  selector: 'wsl-device-info',
  templateUrl: './device-info.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceInfoComponent implements OnInit, OnChanges {
  @Input() device: IWSLDevice;
  @Input() objects: IWSLObject[];
  @Input() rooms: IWSLRoom[];
  @Input() sensors: IWSLDeviceSensor[];
  @Input() confGroups: IWSLDeviceConfGroup[];
  @Input() confs: IWSLDeviceConf[];
  @Input() confData: IWSLDataConf[];
  @Input() groupFileIndexes: IWSLDeviceConfGroupFileIndex[];
  @Output() renameDevice = new EventEmitter<IWSLDevice>();
  @Output() changeModbus = new EventEmitter<string>();

  private dmodels: IWSLDeviceModel[] = [];
  private dexecutions: IWSLDeviceExecution[] = [];

  dmodel: IWSLDeviceModel;
  dexecution: IWSLDeviceExecution;
  object: IWSLObject;
  room: IWSLRoom;
  confGroup: IWSLDeviceConfGroup;
  confGroupResources = [];
  confGroupProtocols = [];
  groupFileIndex: IWSLDeviceConfGroupFileIndex;
  alias = '';
  modbus = '';
  showConfData = false;
  private MModal;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.dexecutions = data.dexecutions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device) {
      this.define();
    }
    if (changes.objects) {
      this.defineObject();
    }
    if (changes.rooms) {
      this.defineRoom();
    }
    if (changes.confGroups) {
      this.defineConfGroup();
    }
    if (changes.groupFileIndexes) {
      this.defineConfGroupFileIndex();
    }
    if (changes.sensors) {
      if (this.sensors) {
        this.sensors.sort((a, b) => a.entry < b.entry ? -1 : 1);
      }
    }
  }

  private define() {
    if (this.device) {
      this.dmodel = DeviceModelService.getModel(this.device, this.dmodels);
      this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
      this.alias = this.device.alias;
      this.modbus = this.device.modbus_addr;
      this.defineObject();
      this.defineRoom();
    }
  }

  private defineObject() {
    this.object = this.objects && this.device ? this.objects.find(o => o.id === this.device.object_id) : null;
  }

  private defineRoom() {
    this.room = this.rooms && this.device ? this.rooms.find(r => r.id === this.device.room_id) : null;
  }

  private defineConfGroup() {
    this.confGroup = null;
    if (this.device && this.device.group_id && this.confGroups && this.confGroups.length > 0) {
      this.confGroup = this.confGroups.find(g => g.id === this.device.group_id);
      if (this.confGroup) {
        this.confGroupResources = this.confGroup.ext && this.confGroup.ext.resources ?
          AllResources.filter(ar => this.confGroup.ext.resources.map(r => r.resource_id).includes(ar.id)) :
          [];
        this.confGroupProtocols = this.confGroup.protocols ? KaratLoraProtocolService.get().filter(p => this.confGroup.protocols.includes(p.id)) : [];
      }
    }
  }

  private defineConfGroupFileIndex() {
    if (this.device && this.device.group_file_id && this.groupFileIndexes && this.groupFileIndexes.length > 0) {
      this.groupFileIndex = this.groupFileIndexes.find(f => f.id === this.device.group_file_id);
    }
  }

  onRenameDevice() {
    if (this.alias !== this.device.alias && !this.device.individual) {
      this.renameDevice.emit({...this.device, alias: this.alias});
    }
  }

  onShowConfData() {
    this.MModal = WSLMaterializeHelper.initModal('#device_conf_data', {dismissible: true});
    this.openModal();
    // this.loadConfs.emit({dconf_ids: group.dconf_ids});
  }

  openModal() {
    this.MModal.open();
  }

  closeModal() {
    this.MModal.close();
  }

  trackById(index: number, obj: any): any {
    return obj.id;
  }

  onChangeDeviceModbus() {
    if (this.modbus !== this.device.modbus_addr) {
      this.changeModbus.emit(this.modbus);
    }
  }
}
