import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConnectionType, ConnectionTypes} from '../../enums/connection-type.enum';
import {IWSLCommunicator} from 'wsl-device';
import {IWSLAsyncErrors, WSLFormHelper, WSLFormValidators} from 'wsl-core';
import {CommunicatorService} from '../../services/communicator.service';
import {debounceTime, distinctUntilChanged, map, take, takeUntil} from 'rxjs/operators';
import {merge, Subject} from 'rxjs';
import {
  CancelButtonType,
  IWSLActionButton,
  IWSLFabActionButton,
  SaveButtonType,
  WSLActionButtonHelper,
  WSLActionButtonType,
  WSLMaterializeHelper
} from 'wsl-shared';
import {LoraNodeClasses} from '../../enums/lora-node-class.enum';
import {LoRaWANProtocols} from '../../enums/lorawan-protocol.enum';

const DEVEUI = [/[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, '-', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, '-', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, '-', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/];

@Component({
  selector: 'wsl-b2b-communicator-form',
  templateUrl: './communicator-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicatorFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() objectId: number;
  @Input() communicator: IWSLCommunicator;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() showActionButtons = false;

  @Input()
  set disabled(isDisable: boolean) {
    this.readonly = isDisable;
    if (isDisable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.defineFormRules();
    this.form.updateValueAndValidity();
  }

  @Output() save = new EventEmitter<IWSLCommunicator>();
  @Output() cancel = new EventEmitter();

  /** @internal */
  readonly = false;
  /** @internal */
  form = new FormGroup({
    id: new FormControl(null),
    object_id: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required]),
    barcode: new FormControl(null),
    archive: new FormGroup({
      try_count: new FormControl(1, [Validators.min(1), Validators.max(3)]),
      try_interval: new FormControl(3, [Validators.min(3), Validators.max(30)])
    }),
    network: new FormGroup({
      serial_num: new FormControl(null),
      channel_id: new FormControl(null, [Validators.required]),
      on_board: new FormControl({value: true, disabled: true}),
      tcp: new FormGroup({
        enable: new FormControl({value: false, disabled: true}),
        ip: new FormControl({value: null, disabled: true}),
        port: new FormControl({value: null, disabled: true})
      }),
      gprs: new FormGroup({
        enable: new FormControl({value: false, disabled: true}),
        id1: new FormControl({value: null, disabled: true}),
        id2: new FormControl({value: null, disabled: true})
      }),
      lora: new FormGroup({
        enable: new FormControl({value: false, disabled: true}),
        devEUI: new FormControl({value: null, disabled: true}),
        lora_node_class: new FormControl({value: null, disabled: true}),
        lorawan_protocol: new FormControl({value: null, disabled: true})
      })
    }),
    vr: new FormControl({value: null, disabled: true})
  });
  /** @internal */
  networkChannels = ConnectionTypes;
  /** @internal */
  masks = {
    devEUI: DEVEUI
  };
  /** @internal */
  gprsGate = '';
  /** @internal */
  actionButtons: Array<IWSLActionButton | IWSLFabActionButton> = [
    {...SaveButtonType},
    {...CancelButtonType}
  ];

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private chr: ChangeDetectorRef,
              private communicatorService: CommunicatorService) {
  }

  ngOnInit() {
    // @todo пока что нет gprs
    // this.loadGPRSGate();

    this.fillForm();
    this.subscribeChanges();
    this.actionButtons = WSLActionButtonHelper.dirtySaveActionButton(this.form, this.actionButtons);
    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(350), distinctUntilChanged())
      .subscribe(val => {
        if (!this.readonly) {
          this.actionButtons = WSLActionButtonHelper.dirtySaveActionButton(this.form, this.actionButtons);
        } else {
          this.actionButtons = WSLActionButtonHelper.dirtySaveActionButton(<any>{dirty: false}, this.actionButtons);
        }
        this.actionButtons = this.actionButtons.slice(0);
      });
  }

  ngOnChanges(changes) {
    if (changes.communicator) {
      this.fillForm();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  get isTCP() {
    return this.form.get('network').get('channel_id').value === ConnectionType.tcp ||
      this.form.get('network').get('channel_id').value === ConnectionType.tcp_gprs;
  }

  get isGPRS() {
    return this.form.get('network').get('channel_id').value === ConnectionType.gprs ||
      this.form.get('network').get('channel_id').value === ConnectionType.tcp_gprs;
  }

  get isLora() {
    return this.form.get('network').get('channel_id').value === ConnectionType.lora;
  }

  get loraNodeClass() {
    return this.communicator && this.communicator.network && this.communicator.network.lora &&
    this.communicator.network.lora.lora_class_id ? LoraNodeClasses.find(c => c.id === +this.communicator.network.lora.lora_class_id) : null;
  }

  get loraProtocolVersion() {
    return this.communicator && this.communicator.network && this.communicator.network.lora &&
    this.communicator.network.lora.lora_version_id ? LoRaWANProtocols.find(c => c.id === +this.communicator.network.lora.lora_version_id) : null;
  }

  onBtnClick(ev) {
    switch (ev) {
      case WSLActionButtonType.save:
        this.form.updateValueAndValidity();
        this.form.markAsDirty();
        if (this.form.valid) {
          const data = this.form.getRawValue();
          if (!this.isLora) {
            delete data.network.lora;
          }
          if (!this.isTCP) {
            delete data.network.tcp;
          }
          if (!this.isGPRS) {
            delete data.network.gprs;
          }
          this.save.emit({
            ...data
          });
        } else {
          WSLMaterializeHelper.toast({html: 'Есть ошибки заполнения формы'});
        }
        break;
      case WSLActionButtonType.cancel:
        this.cancel.emit();
        break;
    }
  }

  private subscribeChanges() {
    merge(
      this.form.get('network').get('tcp').get('enable').valueChanges,
      this.form.get('network').get('gprs').get('enable').valueChanges,
      this.form.get('network').get('lora').get('enable').valueChanges)
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data) => {
        this.defineFormRules();
        this.form.updateValueAndValidity();
        this.chr.markForCheck();
      });
  }

  private fillForm() {
    if (this.communicator) {
      WSLFormHelper.fillForm(this.form, this.communicator);
      /* this.form.get('network').get('serial_num').disable();
       this.form.get('barcode').disable();*/
      switch (this.communicator.network.channel_id) {
        case ConnectionType.tcp:
          this.form.get('network').get('tcp').get('enable').setValue(true);
          break;
        case ConnectionType.gprs:
          this.form.get('network').get('gprs').get('enable').setValue(true);
          break;
        case ConnectionType.tcp_gprs:
          this.form.get('network').get('tcp').get('enable').setValue(true);
          this.form.get('network').get('gprs').get('enable').setValue(true);
          break;
        case ConnectionType.lora:
          this.form.get('network').get('lora').get('enable').setValue(true);
          if (this.communicator.network.lora.lora_class_id) {
            const lc = LoraNodeClasses.find(c => c.id === +this.communicator.network.lora.lora_class_id);
            this.form.get('network').get('lora').get('lora_node_class').setValue(lc ? lc.name : '');
          }
          if (this.communicator.network.lora.lora_version_id) {
            const lp = LoRaWANProtocols.find(c => c.id === +this.communicator.network.lora.lora_version_id);
            this.form.get('network').get('lora').get('lorawan_protocol').setValue(lp ? lp.name : '');
          }
          break;
      }
    } else {
      this.form.get('network').get('on_board').setValue(false, {emitEvent: false});
      this.form.get('object_id').setValue(this.objectId, {emitEvent: false});
      /*if (!this.readonly) {
        this.form.get('network').get('serial_num').enable();
        this.form.get('barcode').enable();
      }*/
      // this.form.reset();
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
    this.defineFormRules();
  }

  private defineFormRules() {
    this.form.get('network').get('serial_num').disable({emitEvent: false});
    this.form.get('barcode').disable({emitEvent: false});
    if (!this.readonly && !this.form.get('network').get('on_board').value) {
      this.form.get('network').get('serial_num').enable({emitEvent: false});
      this.form.get('barcode').enable({emitEvent: false});
    }
    this.form.get('network').get('on_board').disable({emitEvent: false});
    if (!this.communicator) {
      this.form.get('network').get('lora').get('enable').enable({emitEvent: false});
    }

    if (this.form.get('network').get('tcp').get('enable').value) {
      this.form.get('network').get('tcp').get('ip').setValidators([Validators.required, WSLFormValidators.ipValidator]);
      this.form.get('network').get('tcp').get('ip').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('tcp').get('port').setValidators([Validators.required, WSLFormValidators.tcpPortValidator]);
      this.form.get('network').get('tcp').get('port').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('tcp').get('ip').enable({emitEvent: false});
      this.form.get('network').get('tcp').get('port').enable({emitEvent: false});
    } else {
      this.form.get('network').get('tcp').get('ip').clearValidators();
      this.form.get('network').get('tcp').get('ip').reset('', {emitEvent: false});
      this.form.get('network').get('tcp').get('ip').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('tcp').get('port').clearValidators();
      this.form.get('network').get('tcp').get('port').reset('', {emitEvent: false});
      this.form.get('network').get('tcp').get('port').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('tcp').get('ip').disable({emitEvent: false});
      this.form.get('network').get('tcp').get('port').disable({emitEvent: false});
    }

    if (this.form.get('network').get('gprs').get('enable').value) {
      this.form.get('network').get('gprs').get('id1').setValidators([Validators.required]);
      this.form.get('network').get('gprs').get('id1').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('gprs').get('id1').enable({emitEvent: false});
      this.form.get('network').get('gprs').get('id2').enable({emitEvent: false});
    } else {
      this.form.get('network').get('gprs').get('id1').clearValidators();
      this.form.get('network').get('gprs').get('id1').reset('', {emitEvent: false});
      this.form.get('network').get('gprs').get('id1').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('gprs').get('id2').clearValidators();
      this.form.get('network').get('gprs').get('id2').reset('', {emitEvent: false});
      this.form.get('network').get('gprs').get('id2').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('gprs').get('id1').disable({emitEvent: false});
      this.form.get('network').get('gprs').get('id2').disable({emitEvent: false});
    }

    if (this.form.get('network').get('lora').get('enable').value) {
      this.form.get('network').get('lora').get('devEUI').setValidators([Validators.required, WSLFormValidators.devEUIValidator]);
      this.form.get('network').get('lora').get('devEUI').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').enable({emitEvent: false});
      this.form.get('network').get('lora').get('lora_node_class').enable({emitEvent: false});
      this.form.get('network').get('lora').get('lorawan_protocol').enable({emitEvent: false});
    } else {
      this.form.get('network').get('lora').get('devEUI').clearValidators();
      this.form.get('network').get('lora').get('devEUI').reset('', {emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').updateValueAndValidity({emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lora_node_class').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lorawan_protocol').disable({emitEvent: false});
    }

    this.form.get('network').get('channel_id').setValue(null);

    if (this.form.get('network').get('tcp').get('enable').value && this.form.get('network').get('gprs').get('enable').value) {
      this.form.get('network').get('channel_id').setValue(ConnectionType.tcp_gprs, {emitEvent: false});

      this.form.get('network').get('lora').get('enable').disable({emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lora_node_class').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lorawan_protocol').disable({emitEvent: false});

    } else if (this.form.get('network').get('tcp').get('enable').value) {
      this.form.get('network').get('channel_id').setValue(ConnectionType.tcp, {emitEvent: false});

      this.form.get('network').get('lora').get('enable').disable({emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lora_node_class').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lorawan_protocol').disable({emitEvent: false});

    } else if (this.form.get('network').get('gprs').get('enable').value) {
      this.form.get('network').get('channel_id').setValue(ConnectionType.gprs, {emitEvent: false});

      this.form.get('network').get('lora').get('enable').disable({emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lora_node_class').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lorawan_protocol').disable({emitEvent: false});

    } else if (this.form.get('network').get('lora').get('enable').value) {
      this.form.get('network').get('channel_id').setValue(ConnectionType.lora, {emitEvent: false});
      this.form.get('network').get('tcp').get('enable').disable({emitEvent: false});
      this.form.get('network').get('tcp').get('ip').disable({emitEvent: false});
      this.form.get('network').get('tcp').get('port').disable({emitEvent: false});
      this.form.get('network').get('gprs').get('enable').disable({emitEvent: false});
      this.form.get('network').get('gprs').get('id1').disable({emitEvent: false});
      this.form.get('network').get('gprs').get('id2').disable({emitEvent: false});
    } else {
      this.form.get('network').get('tcp').get('enable').enable({emitEvent: false});
      /* this.form.get('network').get('tcp').get('ip').enable({emitEvent: false});
       this.form.get('network').get('tcp').get('port').enable({emitEvent: false});*/
      this.form.get('network').get('gprs').get('enable').enable({emitEvent: false});
      /*this.form.get('network').get('gprs').get('id1').enable({emitEvent: false});
      this.form.get('network').get('gprs').get('id2').enable({emitEvent: false});*/
      // this.form.get('network').get('lora').get('enable').enable({emitEvent: false});
    }

    if (this.communicator && this.communicator.network.on_board) {
      this.form.get('network').get('lora').get('enable').disable({emitEvent: false});
      this.form.get('network').get('lora').get('devEUI').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lora_node_class').disable({emitEvent: false});
      this.form.get('network').get('lora').get('lorawan_protocol').disable({emitEvent: false});
    }
    this.form.get('vr').disable();
  }

  private loadGPRSGate() {
    this.communicatorService.getGPRSGate()
      .pipe(
        take(1),
        map(resp => {
          this.gprsGate = (<any>resp).item;
          this.chr.markForCheck();
        })
      ).subscribe();
  }

}
