import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IWSLDeviceSensor} from 'wsl-device';
import {WSLRouterHelperService} from 'wsl-core';
import {ActivatedRoute} from '@angular/router';
import {AllResources, IWSLResource, IWSLResourceValue, IWSLResourceVariable, ResourceVariableType} from 'wsl-ek-core';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';

@Component({
  selector: 'wsl-device-sensor-info',
  templateUrl: './device-sensor-info.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSensorInfoComponent implements OnInit, OnChanges {
  @Input() sensor: IWSLDeviceSensor;
  @Input() confData: IWSLDataConf[];

  private rvariables: IWSLResourceVariable[] = [];
  private rvalues: IWSLResourceValue[] = [];

  resource: IWSLResource = null;
  resourceNum: number = null;
  rvalue: IWSLResourceValue = null;
  rvariable: IWSLResourceVariable = null;
  showImpulseWeight = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.rvariables = data.resourceVariables;
    this.rvalues = data.resourceValues;
    this.showImpulseWeight = !!this.sensor.impulse_weight;
    this.defineResource();
  //  this.showImpulseWeight = [ResourceVariableType.q, ResourceVariableType.v, ResourceVariableType.e].includes(this.input.rvariable.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.confData) {
      this.defineResource();
    }
  }

  private defineResource() {
    const conf = !!this.confData ? this.confData.find(c => c.id === this.sensor.dconf_ext_id) : null;
    this.resource = conf ? AllResources.find(r => r.id === conf.resource_id) : this.sensor.resource_id
      ? AllResources.find(r => r.id === this.sensor.resource_id) : null;
    this.resourceNum = conf ? conf.resource_inx : null;
    this.rvalue = this.rvalues && conf ? this.rvalues.find(r => r.id === conf.rvalue_id) : null;
    this.rvariable = this.rvalues && this.sensor.rvariable_id ? this.rvariables.find(r => r.id === this.sensor.rvariable_id) : null;
    this.showImpulseWeight = conf ? [ResourceVariableType.q, ResourceVariableType.v, ResourceVariableType.e].includes(conf.rvariable_id) : false;
  }
}
