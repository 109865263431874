import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DECODER_LIVE_CONFIG } from '../../../../config/barcode-decoder.config';
import { WSLMaterializeHelper } from 'wsl-shared';
// import Quagga from 'quagga';

// {"input": "../node_modules/webrtc-adapter/out/adapter.js", "lazy": true},
// {"input": "../node_modules/quagga/dist/quagga.min.js", "lazy": true}

declare var Quagga: any;

@Component({
  selector: 'wsl-scan-barcode',
  templateUrl: './scan-barcode.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanBarcodeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prefixID = '';
  @Input() barcode: string;
  @Input() disabled = false;
  @Input() showBack = true;
  @Output() back = new EventEmitter();
  @Output() next = new EventEmitter<string>();

  /** @internal */
  enableCamera = true;
  /** @internal */
  showVideo = false;
  /** @internal */
  _barcode = null;
  /** @internal */
  resultCollector = Quagga.ResultCollector.create({
    capture: true,
    capacity: 5,
    blacklist: [],
    filter: function (codeResult) {
      // only store results which match this constraint
      // e.g.: codeResult
       return codeResult.format === 'code_128';
    }
  });

  private quaggaInited = false;

  constructor(private chr: ChangeDetectorRef) { }

  ngOnInit() {
    this.fillForm();
  }

  ngOnChanges(changes) {
    if (changes.barcode) {
      this.fillForm();
    }
  }

  ngOnDestroy() {
    this.chr.detach();
    this.stopQuagga();
  }

  clear() {
    this._barcode = '';
  }

  handleBack() {
    this.stopQuagga();
    this.back.emit();
  }

  handleNext() {
    if (!this.disabled) {
      if (this._barcode && Number.isInteger(+this._barcode) && this._barcode.length > 5) {
        this.stopQuagga();
        this.showVideo = false;
        this.next.emit(this._barcode);
      } else {
        WSLMaterializeHelper.toast({html: 'укажите код', displayLength: 2000});
      }
    }
  }

  startScanner() {
    if (this.enableCamera && !this.disabled) {
      this._barcode = '';
      this.showVideo = true;
      this.chr.detectChanges();
      // Quagga.stop();

      if (!this.quaggaInited) {
        Quagga.onProcessed((result) => this.onProcessed(result));
        Quagga.onDetected((result) => this.onDetected(result));
      }
      Quagga.init(Object.assign({}, DECODER_LIVE_CONFIG, {
        // locate: false,
        inputStream: {
          ...DECODER_LIVE_CONFIG.inputStream,
          target: document.querySelector('.' + this.prefixID + '_viewport')
        }
      }), (err) => {
        if (err) {
          return this.handleError(err);
        }
        this.quaggaInited = true;
        Quagga.registerResultCollector(this.resultCollector);
        // this.checkCapabilities();
        Quagga.start();
        this.chr.markForCheck();
      });
    }
  }

  private stopQuagga() {
    if (this.quaggaInited) {
      Quagga.stop();
     // Quagga.offProcessed(this.onProcessed);
     // Quagga.offDetected(this.onDetected);
      this.quaggaInited = false;
      this.showVideo = false;
    }
  }

  private handleError(err) {
    console.error(err);
    WSLMaterializeHelper.toast({
      html: `Ваш браузер не поддерживает работу с камерой
    ${err.name}
    ${err.message}
    ${err.constraintName}`, displayLength: 5000
    });
    this.showVideo = false;
    this.enableCamera = false;
    this.chr.detectChanges();
  }

  private fillForm() {
    if (this.barcode) {
      this._barcode = this.barcode;
    }
  }

  private checkCapabilities() {
    const track = Quagga.CameraAccess.getActiveTrack();
    let capabilities = {};
    if (typeof track.getCapabilities === 'function') {
      capabilities = track.getCapabilities();
    }
    // this.applySettingsVisibility('zoom', capabilities.zoom);
    // this.applySettingsVisibility('torch', capabilities.torch);
  }

  private onProcessed(result: any) {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
   // drawingCanvas.style.display = 'none';

    drawingCtx.clearRect(0, 0,
        parseInt(drawingCanvas.getAttribute('width'), 10),
        parseInt(drawingCanvas.getAttribute('height'), 10));
    if (result) {
      if (result.boxes) {

        result.boxes.filter(function (box) {
          return box !== result.box;
        }).forEach(function (box) {
          Quagga.ImageDebug.drawPath(box,
            {x: 0, y: 1}, drawingCtx, {color: 'green', lineWidth: 2});
        });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: '#00F', lineWidth: 2});
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
      }
    }
  }

  private onDetected(result: any) {
    if (result && result.codeResult) {
      const code = result.codeResult.code;
      if (code && Number.isInteger(+code)) {
        if (this._barcode !== code) {
          // showMaterializeToast(`сканирован код ${code}`, 2000);
          this._barcode = code;
          /*let $node = null;
          const canvas = Quagga.canvas.dom.image;

          $node = $('<li><div class="thumbnail">' +
            '<div class="imgWrapper"><img /></div><div class="caption"><h4 class="code"></h4></div></div></li>');
          $node.find('img').attr('src', canvas.toDataURL());
          $node.find('h4.code').html(code);
          $('#result_strip ul.thumbnails').prepend($node);
          this.printCollectedResults();*/
          this.showVideo = false;
          this.stopQuagga();
          this.chr.markForCheck();
          /*setTimeout(() => {
            this.stopQuagga();
            this.chr.markForCheck();
          });*/
        }
      }
    }
  }

  /*private printCollectedResults () {
    const results = this.resultCollector.getResults(),
      $ul = $('#result_strip ul.collector');

    results.forEach(function(result) {
      const $li = $('<li><div class="thumbnail"><div class="imgWrapper"><img /></div><div class="caption">' +
        '<h4 class="code"></h4></div></div></li>');

      $li.find('img').attr('src', result.frame);
      $li.find('h4.code').html(result.codeResult.code + ' (' + result.codeResult.format + ')');
      $ul.prepend($li);
    });
  }*/

}
