import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IWSLDeviceConfGroup} from '../../models/device-conf-group';
import {IWSLDeviceModel} from 'wsl-device';
import {WSLRouterHelperService} from 'wsl-core';
import {ActivatedRoute} from '@angular/router';
import {AllResources} from 'wsl-ek-core';
import {KaratLoraProtocolService} from '@core/services/karat-lora-protocol.service';

@Component({
  selector: '[wsl-device-conf-group-row]',
  templateUrl: './device-conf-group-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfGroupRowComponent implements OnInit, OnChanges {
  @Input() group: IWSLDeviceConfGroup;
  @Input() checked = false;
  @Input() checkable = false;
  @Output() check = new EventEmitter<{ id: number, checked: boolean }>();
  @Output() selectItem = new EventEmitter<IWSLDeviceConfGroup>();
  @Output() delete = new EventEmitter<number>();
  /** @internal */
  dmodels: IWSLDeviceModel[] = [];
  resources = [];
  protocols = [];
  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.define();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.group) {
      this.define();
    }
  }

  /* get resources() {
     return this.group.ext && this.group.ext.resources ?
       AllResources.filter(ar => this.group.ext.resources.map(r => r.resource_id).includes(ar.id)) :
       [];
   }

   get connection() {
     return this.group.type ? DeviceConnectionTypes.find(dct => dct.id === this.group.type) :
       (this.isLora ? DeviceConnectionTypes.find(dct => dct.id === DeviceConnectionType.lora) :
         DeviceConnectionTypes.find(dct => dct.id === DeviceConnectionType.eth));
   }

   get isLora() {
     return this.group.type === DeviceConnectionType.lora;
   }*/

  /*  onSelect() {
      this.selectItem.emit(this.conf.id);
    }*/

  onCheck($event) {
    this.check.emit({id: this.group.id, checked: $event});
  }

  onDelete() {
    this.delete.emit(this.group.id);
  }

  private define() {
    this.resources = this.group.ext && this.group.ext.resources ?
      this.group.ext.resources.map(r => AllResources.find(ar => ar.id === r.resource_id)) :
      // AllResources.filter(ar => this.group.ext.resources.map(r => r.resource_id).includes(ar.id)) :
      [];
    this.protocols = this.group.protocols ? KaratLoraProtocolService.get().filter(p => this.group.protocols.includes(p.id)) : [];
  }

}
