import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'wsl-add-device-select-placement-type',
  templateUrl: './add-device-select-placement-type.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceSelectPlacementTypeComponent implements OnInit {
  @Output() selectPlacementType = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  onSelectType(ipu: boolean) {
    this.selectPlacementType.emit(ipu);
  }

}
