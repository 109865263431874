export let DECODER_LIVE_CONFIG;
DECODER_LIVE_CONFIG = {
  numOfWorkers: 2,
  inputStream: {
    name: 'Live',
    type : 'LiveStream',
   /* constraints: {
      width: {min: 1280},
      height: {min: 640},
      facingMode: 'environment',
      aspectRatio: {min: 1, max: 1.1}
    },*/
    area: { // defines rectangle of the detection/localization area
      top: "0%",    // top offset
      right: "0%",  // right offset
      left: "0%",   // left offset
      bottom: "0%"  // bottom offset
    }
  },
  decoder: {
    readers: [
        'code_128_reader'
    ],
    debug: {
      drawBoundingBox: false,
      showFrequency: false,
      drawScanline: false,
      showPattern: false
    },
    multiple: false
  },
  locate: true,
  locator: {
    patchSize: 'small', // 'medium',
    halfSample: false,
    debug: {
      showCanvas: false,
      showPatches: false,
      showFoundPatches: false,
      showSkeleton: false,
      showLabels: false,
      showPatchLabels: false,
      showRemainingPatchLabels: false,
      boxFromPatches: {
        showTransformed: false,
        showTransformedBox: false,
        showBB: false
      }
    }
  },
  frequency: 10
};
