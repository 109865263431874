import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionType, ConnectionTypes } from '../../enums/connection-type.enum';
import { WSLFormHelper, WSLFormValidators } from 'wsl-core';
import { CommunicatorService } from '../../services/communicator.service';
import { debounceTime, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { CancelButtonType, SaveButtonType, WSLActionButtonHelper, WSLActionButtonType, WSLMaterializeHelper } from 'wsl-shared';
import { LoraNodeClasses } from '../../enums/lora-node-class.enum';
import { LoRaWANProtocols } from '../../enums/lorawan-protocol.enum';
var DEVEUI = [/[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, '-', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, '-', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, '-', /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/, /[0-9a-fA-F]/];
var CommunicatorFormComponent = /** @class */ (function () {
    function CommunicatorFormComponent(chr, communicatorService) {
        this.chr = chr;
        this.communicatorService = communicatorService;
        this.showActionButtons = false;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        /** @internal */
        this.readonly = false;
        /** @internal */
        this.form = new FormGroup({
            id: new FormControl(null),
            object_id: new FormControl(null, [Validators.required]),
            name: new FormControl(null, [Validators.required]),
            barcode: new FormControl(null),
            archive: new FormGroup({
                try_count: new FormControl(1, [Validators.min(1), Validators.max(3)]),
                try_interval: new FormControl(3, [Validators.min(3), Validators.max(30)])
            }),
            network: new FormGroup({
                serial_num: new FormControl(null),
                channel_id: new FormControl(null, [Validators.required]),
                on_board: new FormControl({ value: true, disabled: true }),
                tcp: new FormGroup({
                    enable: new FormControl({ value: false, disabled: true }),
                    ip: new FormControl({ value: null, disabled: true }),
                    port: new FormControl({ value: null, disabled: true })
                }),
                gprs: new FormGroup({
                    enable: new FormControl({ value: false, disabled: true }),
                    id1: new FormControl({ value: null, disabled: true }),
                    id2: new FormControl({ value: null, disabled: true })
                }),
                lora: new FormGroup({
                    enable: new FormControl({ value: false, disabled: true }),
                    devEUI: new FormControl({ value: null, disabled: true }),
                    lora_node_class: new FormControl({ value: null, disabled: true }),
                    lorawan_protocol: new FormControl({ value: null, disabled: true })
                })
            }),
            vr: new FormControl({ value: null, disabled: true })
        });
        /** @internal */
        this.networkChannels = ConnectionTypes;
        /** @internal */
        this.masks = {
            devEUI: DEVEUI
        };
        /** @internal */
        this.gprsGate = '';
        /** @internal */
        this.actionButtons = [
            tslib_1.__assign({}, SaveButtonType),
            tslib_1.__assign({}, CancelButtonType)
        ];
        this.ngUnsubscribe = new Subject();
    }
    Object.defineProperty(CommunicatorFormComponent.prototype, "disabled", {
        set: function (isDisable) {
            this.readonly = isDisable;
            if (isDisable) {
                this.form.disable();
            }
            else {
                this.form.enable();
            }
            this.defineFormRules();
            this.form.updateValueAndValidity();
        },
        enumerable: true,
        configurable: true
    });
    CommunicatorFormComponent.prototype.ngOnInit = function () {
        // @todo пока что нет gprs
        // this.loadGPRSGate();
        var _this = this;
        this.fillForm();
        this.subscribeChanges();
        this.actionButtons = WSLActionButtonHelper.dirtySaveActionButton(this.form, this.actionButtons);
        this.form.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe), debounceTime(350), distinctUntilChanged())
            .subscribe(function (val) {
            if (!_this.readonly) {
                _this.actionButtons = WSLActionButtonHelper.dirtySaveActionButton(_this.form, _this.actionButtons);
            }
            else {
                _this.actionButtons = WSLActionButtonHelper.dirtySaveActionButton({ dirty: false }, _this.actionButtons);
            }
            _this.actionButtons = _this.actionButtons.slice(0);
        });
    };
    CommunicatorFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.communicator) {
            this.fillForm();
        }
    };
    CommunicatorFormComponent.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    Object.defineProperty(CommunicatorFormComponent.prototype, "isTCP", {
        get: function () {
            return this.form.get('network').get('channel_id').value === ConnectionType.tcp ||
                this.form.get('network').get('channel_id').value === ConnectionType.tcp_gprs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorFormComponent.prototype, "isGPRS", {
        get: function () {
            return this.form.get('network').get('channel_id').value === ConnectionType.gprs ||
                this.form.get('network').get('channel_id').value === ConnectionType.tcp_gprs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorFormComponent.prototype, "isLora", {
        get: function () {
            return this.form.get('network').get('channel_id').value === ConnectionType.lora;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorFormComponent.prototype, "loraNodeClass", {
        get: function () {
            var _this = this;
            return this.communicator && this.communicator.network && this.communicator.network.lora &&
                this.communicator.network.lora.lora_class_id ? LoraNodeClasses.find(function (c) { return c.id === +_this.communicator.network.lora.lora_class_id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CommunicatorFormComponent.prototype, "loraProtocolVersion", {
        get: function () {
            var _this = this;
            return this.communicator && this.communicator.network && this.communicator.network.lora &&
                this.communicator.network.lora.lora_version_id ? LoRaWANProtocols.find(function (c) { return c.id === +_this.communicator.network.lora.lora_version_id; }) : null;
        },
        enumerable: true,
        configurable: true
    });
    CommunicatorFormComponent.prototype.onBtnClick = function (ev) {
        switch (ev) {
            case WSLActionButtonType.save:
                this.form.updateValueAndValidity();
                this.form.markAsDirty();
                if (this.form.valid) {
                    var data = this.form.getRawValue();
                    if (!this.isLora) {
                        delete data.network.lora;
                    }
                    if (!this.isTCP) {
                        delete data.network.tcp;
                    }
                    if (!this.isGPRS) {
                        delete data.network.gprs;
                    }
                    this.save.emit(tslib_1.__assign({}, data));
                }
                else {
                    WSLMaterializeHelper.toast({ html: 'Есть ошибки заполнения формы' });
                }
                break;
            case WSLActionButtonType.cancel:
                this.cancel.emit();
                break;
        }
    };
    CommunicatorFormComponent.prototype.subscribeChanges = function () {
        var _this = this;
        merge(this.form.get('network').get('tcp').get('enable').valueChanges, this.form.get('network').get('gprs').get('enable').valueChanges, this.form.get('network').get('lora').get('enable').valueChanges)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (data) {
            _this.defineFormRules();
            _this.form.updateValueAndValidity();
            _this.chr.markForCheck();
        });
    };
    CommunicatorFormComponent.prototype.fillForm = function () {
        var _this = this;
        if (this.communicator) {
            WSLFormHelper.fillForm(this.form, this.communicator);
            /* this.form.get('network').get('serial_num').disable();
             this.form.get('barcode').disable();*/
            switch (this.communicator.network.channel_id) {
                case ConnectionType.tcp:
                    this.form.get('network').get('tcp').get('enable').setValue(true);
                    break;
                case ConnectionType.gprs:
                    this.form.get('network').get('gprs').get('enable').setValue(true);
                    break;
                case ConnectionType.tcp_gprs:
                    this.form.get('network').get('tcp').get('enable').setValue(true);
                    this.form.get('network').get('gprs').get('enable').setValue(true);
                    break;
                case ConnectionType.lora:
                    this.form.get('network').get('lora').get('enable').setValue(true);
                    if (this.communicator.network.lora.lora_class_id) {
                        var lc = LoraNodeClasses.find(function (c) { return c.id === +_this.communicator.network.lora.lora_class_id; });
                        this.form.get('network').get('lora').get('lora_node_class').setValue(lc ? lc.name : '');
                    }
                    if (this.communicator.network.lora.lora_version_id) {
                        var lp = LoRaWANProtocols.find(function (c) { return c.id === +_this.communicator.network.lora.lora_version_id; });
                        this.form.get('network').get('lora').get('lorawan_protocol').setValue(lp ? lp.name : '');
                    }
                    break;
            }
        }
        else {
            this.form.get('network').get('on_board').setValue(false, { emitEvent: false });
            this.form.get('object_id').setValue(this.objectId, { emitEvent: false });
            /*if (!this.readonly) {
              this.form.get('network').get('serial_num').enable();
              this.form.get('barcode').enable();
            }*/
            // this.form.reset();
        }
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.form.updateValueAndValidity();
        this.defineFormRules();
    };
    CommunicatorFormComponent.prototype.defineFormRules = function () {
        this.form.get('network').get('serial_num').disable({ emitEvent: false });
        this.form.get('barcode').disable({ emitEvent: false });
        if (!this.readonly && !this.form.get('network').get('on_board').value) {
            this.form.get('network').get('serial_num').enable({ emitEvent: false });
            this.form.get('barcode').enable({ emitEvent: false });
        }
        this.form.get('network').get('on_board').disable({ emitEvent: false });
        if (!this.communicator) {
            this.form.get('network').get('lora').get('enable').enable({ emitEvent: false });
        }
        if (this.form.get('network').get('tcp').get('enable').value) {
            this.form.get('network').get('tcp').get('ip').setValidators([Validators.required, WSLFormValidators.ipValidator]);
            this.form.get('network').get('tcp').get('ip').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('tcp').get('port').setValidators([Validators.required, WSLFormValidators.tcpPortValidator]);
            this.form.get('network').get('tcp').get('port').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('tcp').get('ip').enable({ emitEvent: false });
            this.form.get('network').get('tcp').get('port').enable({ emitEvent: false });
        }
        else {
            this.form.get('network').get('tcp').get('ip').clearValidators();
            this.form.get('network').get('tcp').get('ip').reset('', { emitEvent: false });
            this.form.get('network').get('tcp').get('ip').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('tcp').get('port').clearValidators();
            this.form.get('network').get('tcp').get('port').reset('', { emitEvent: false });
            this.form.get('network').get('tcp').get('port').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('tcp').get('ip').disable({ emitEvent: false });
            this.form.get('network').get('tcp').get('port').disable({ emitEvent: false });
        }
        if (this.form.get('network').get('gprs').get('enable').value) {
            this.form.get('network').get('gprs').get('id1').setValidators([Validators.required]);
            this.form.get('network').get('gprs').get('id1').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('gprs').get('id1').enable({ emitEvent: false });
            this.form.get('network').get('gprs').get('id2').enable({ emitEvent: false });
        }
        else {
            this.form.get('network').get('gprs').get('id1').clearValidators();
            this.form.get('network').get('gprs').get('id1').reset('', { emitEvent: false });
            this.form.get('network').get('gprs').get('id1').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('gprs').get('id2').clearValidators();
            this.form.get('network').get('gprs').get('id2').reset('', { emitEvent: false });
            this.form.get('network').get('gprs').get('id2').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('gprs').get('id1').disable({ emitEvent: false });
            this.form.get('network').get('gprs').get('id2').disable({ emitEvent: false });
        }
        if (this.form.get('network').get('lora').get('enable').value) {
            this.form.get('network').get('lora').get('devEUI').setValidators([Validators.required, WSLFormValidators.devEUIValidator]);
            this.form.get('network').get('lora').get('devEUI').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').enable({ emitEvent: false });
            this.form.get('network').get('lora').get('lora_node_class').enable({ emitEvent: false });
            this.form.get('network').get('lora').get('lorawan_protocol').enable({ emitEvent: false });
        }
        else {
            this.form.get('network').get('lora').get('devEUI').clearValidators();
            this.form.get('network').get('lora').get('devEUI').reset('', { emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').updateValueAndValidity({ emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lora_node_class').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lorawan_protocol').disable({ emitEvent: false });
        }
        this.form.get('network').get('channel_id').setValue(null);
        if (this.form.get('network').get('tcp').get('enable').value && this.form.get('network').get('gprs').get('enable').value) {
            this.form.get('network').get('channel_id').setValue(ConnectionType.tcp_gprs, { emitEvent: false });
            this.form.get('network').get('lora').get('enable').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lora_node_class').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lorawan_protocol').disable({ emitEvent: false });
        }
        else if (this.form.get('network').get('tcp').get('enable').value) {
            this.form.get('network').get('channel_id').setValue(ConnectionType.tcp, { emitEvent: false });
            this.form.get('network').get('lora').get('enable').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lora_node_class').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lorawan_protocol').disable({ emitEvent: false });
        }
        else if (this.form.get('network').get('gprs').get('enable').value) {
            this.form.get('network').get('channel_id').setValue(ConnectionType.gprs, { emitEvent: false });
            this.form.get('network').get('lora').get('enable').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lora_node_class').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lorawan_protocol').disable({ emitEvent: false });
        }
        else if (this.form.get('network').get('lora').get('enable').value) {
            this.form.get('network').get('channel_id').setValue(ConnectionType.lora, { emitEvent: false });
            this.form.get('network').get('tcp').get('enable').disable({ emitEvent: false });
            this.form.get('network').get('tcp').get('ip').disable({ emitEvent: false });
            this.form.get('network').get('tcp').get('port').disable({ emitEvent: false });
            this.form.get('network').get('gprs').get('enable').disable({ emitEvent: false });
            this.form.get('network').get('gprs').get('id1').disable({ emitEvent: false });
            this.form.get('network').get('gprs').get('id2').disable({ emitEvent: false });
        }
        else {
            this.form.get('network').get('tcp').get('enable').enable({ emitEvent: false });
            /* this.form.get('network').get('tcp').get('ip').enable({emitEvent: false});
             this.form.get('network').get('tcp').get('port').enable({emitEvent: false});*/
            this.form.get('network').get('gprs').get('enable').enable({ emitEvent: false });
            /*this.form.get('network').get('gprs').get('id1').enable({emitEvent: false});
            this.form.get('network').get('gprs').get('id2').enable({emitEvent: false});*/
            // this.form.get('network').get('lora').get('enable').enable({emitEvent: false});
        }
        if (this.communicator && this.communicator.network.on_board) {
            this.form.get('network').get('lora').get('enable').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('devEUI').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lora_node_class').disable({ emitEvent: false });
            this.form.get('network').get('lora').get('lorawan_protocol').disable({ emitEvent: false });
        }
        this.form.get('vr').disable();
    };
    CommunicatorFormComponent.prototype.loadGPRSGate = function () {
        var _this = this;
        this.communicatorService.getGPRSGate()
            .pipe(take(1), map(function (resp) {
            _this.gprsGate = resp.item;
            _this.chr.markForCheck();
        })).subscribe();
    };
    return CommunicatorFormComponent;
}());
export { CommunicatorFormComponent };
