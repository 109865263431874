import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { IWSLDeviceVerification } from '../../models/device-verification';
import {DeviceConfGroupService} from '@app/device-conf-group/services/device-conf-group.service';
import {DeviceConfExtService} from '@app/device-conf-ext/services/device-conf-ext.service';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';
import {WSLUtils} from 'wsl-core';
import {take, tap} from 'rxjs/operators';

@Component({
  selector: 'wsl-device-verification-table',
  templateUrl: './device-verification-table.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceVerificationTableComponent implements OnInit, OnChanges {
  @Input() devices: IWSLDeviceVerification[];
  @Input() showObject = true;
  @Input() showRoom = true;

  confGroups: IWSLDeviceConfGroup[] = [];
  confs: IWSLDataConf[] = [];


  /** @internal */
  scrollTop = 0;

  constructor(private deviceConfGroupService: DeviceConfGroupService,
              private deviceConfExtService: DeviceConfExtService,
              private chr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.devices) {
      this.defineConfGroups();
    }
  }

  onScroll(st) {
    this.scrollTop = st;
  }

  trackById(index: number, obj: any): any {
    return obj.tab_id;
  }

  private defineConfGroups() {
    if (this.devices && this.devices.length > 0) {
      const cgroups = WSLUtils.difference(this.devices.map(d => d.group_id), this.confGroups.map(g => g.id));
      if (cgroups.length > 0) {
        this.loadConfGroup();
      }
    }
  }

  private loadConfGroup() {
    this.deviceConfGroupService.getMany({dmodel_ids: this.devices.map(d => d.dmodel_id)})
      .pipe(
        take(1),
        tap(res => {
          this.confGroups = res.items;
          this.loadConfs(this.confGroups.reduce((prev, g) => [...prev, ...g.dconf_ids], []));
        })
      )
      .subscribe();
  }

  private loadConfs(dconf_ids) {
    this.deviceConfExtService.getMany({dconf_ids})
      .pipe(
        take(1),
        tap(res => {
          this.confs = res.items;
          this.chr.markForCheck();
        })
      )
      .subscribe();
  }

}
