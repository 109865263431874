import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DECODER_LIVE_CONFIG } from '../../../../config/barcode-decoder.config';
import { WSLMaterializeHelper } from 'wsl-shared';
var ScanBarcodeComponent = /** @class */ (function () {
    function ScanBarcodeComponent(chr) {
        this.chr = chr;
        this.prefixID = '';
        this.disabled = false;
        this.showBack = true;
        this.back = new EventEmitter();
        this.next = new EventEmitter();
        /** @internal */
        this.enableCamera = true;
        /** @internal */
        this.showVideo = false;
        /** @internal */
        this._barcode = null;
        /** @internal */
        this.resultCollector = Quagga.ResultCollector.create({
            capture: true,
            capacity: 5,
            blacklist: [],
            filter: function (codeResult) {
                // only store results which match this constraint
                // e.g.: codeResult
                return codeResult.format === 'code_128';
            }
        });
        this.quaggaInited = false;
    }
    ScanBarcodeComponent.prototype.ngOnInit = function () {
        this.fillForm();
    };
    ScanBarcodeComponent.prototype.ngOnChanges = function (changes) {
        if (changes.barcode) {
            this.fillForm();
        }
    };
    ScanBarcodeComponent.prototype.ngOnDestroy = function () {
        this.chr.detach();
        this.stopQuagga();
    };
    ScanBarcodeComponent.prototype.clear = function () {
        this._barcode = '';
    };
    ScanBarcodeComponent.prototype.handleBack = function () {
        this.stopQuagga();
        this.back.emit();
    };
    ScanBarcodeComponent.prototype.handleNext = function () {
        if (!this.disabled) {
            if (this._barcode && Number.isInteger(+this._barcode) && this._barcode.length > 5) {
                this.stopQuagga();
                this.showVideo = false;
                this.next.emit(this._barcode);
            }
            else {
                WSLMaterializeHelper.toast({ html: 'укажите код', displayLength: 2000 });
            }
        }
    };
    ScanBarcodeComponent.prototype.startScanner = function () {
        var _this = this;
        if (this.enableCamera && !this.disabled) {
            this._barcode = '';
            this.showVideo = true;
            this.chr.detectChanges();
            // Quagga.stop();
            if (!this.quaggaInited) {
                Quagga.onProcessed(function (result) { return _this.onProcessed(result); });
                Quagga.onDetected(function (result) { return _this.onDetected(result); });
            }
            Quagga.init(Object.assign({}, DECODER_LIVE_CONFIG, {
                // locate: false,
                inputStream: tslib_1.__assign({}, DECODER_LIVE_CONFIG.inputStream, { target: document.querySelector('.' + this.prefixID + '_viewport') })
            }), function (err) {
                if (err) {
                    return _this.handleError(err);
                }
                _this.quaggaInited = true;
                Quagga.registerResultCollector(_this.resultCollector);
                // this.checkCapabilities();
                Quagga.start();
                _this.chr.markForCheck();
            });
        }
    };
    ScanBarcodeComponent.prototype.stopQuagga = function () {
        if (this.quaggaInited) {
            Quagga.stop();
            // Quagga.offProcessed(this.onProcessed);
            // Quagga.offDetected(this.onDetected);
            this.quaggaInited = false;
            this.showVideo = false;
        }
    };
    ScanBarcodeComponent.prototype.handleError = function (err) {
        console.error(err);
        WSLMaterializeHelper.toast({
            html: "\u0412\u0430\u0448 \u0431\u0440\u0430\u0443\u0437\u0435\u0440 \u043D\u0435 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u0438\u0432\u0430\u0435\u0442 \u0440\u0430\u0431\u043E\u0442\u0443 \u0441 \u043A\u0430\u043C\u0435\u0440\u043E\u0439\n    " + err.name + "\n    " + err.message + "\n    " + err.constraintName, displayLength: 5000
        });
        this.showVideo = false;
        this.enableCamera = false;
        this.chr.detectChanges();
    };
    ScanBarcodeComponent.prototype.fillForm = function () {
        if (this.barcode) {
            this._barcode = this.barcode;
        }
    };
    ScanBarcodeComponent.prototype.checkCapabilities = function () {
        var track = Quagga.CameraAccess.getActiveTrack();
        var capabilities = {};
        if (typeof track.getCapabilities === 'function') {
            capabilities = track.getCapabilities();
        }
        // this.applySettingsVisibility('zoom', capabilities.zoom);
        // this.applySettingsVisibility('torch', capabilities.torch);
    };
    ScanBarcodeComponent.prototype.onProcessed = function (result) {
        var drawingCtx = Quagga.canvas.ctx.overlay;
        var drawingCanvas = Quagga.canvas.dom.overlay;
        // drawingCanvas.style.display = 'none';
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
        if (result) {
            if (result.boxes) {
                result.boxes.filter(function (box) {
                    return box !== result.box;
                }).forEach(function (box) {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'green', lineWidth: 2 });
                });
            }
            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
            }
            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
            }
        }
    };
    ScanBarcodeComponent.prototype.onDetected = function (result) {
        if (result && result.codeResult) {
            var code = result.codeResult.code;
            if (code && Number.isInteger(+code)) {
                if (this._barcode !== code) {
                    // showMaterializeToast(`сканирован код ${code}`, 2000);
                    this._barcode = code;
                    /*let $node = null;
                    const canvas = Quagga.canvas.dom.image;
          
                    $node = $('<li><div class="thumbnail">' +
                      '<div class="imgWrapper"><img /></div><div class="caption"><h4 class="code"></h4></div></div></li>');
                    $node.find('img').attr('src', canvas.toDataURL());
                    $node.find('h4.code').html(code);
                    $('#result_strip ul.thumbnails').prepend($node);
                    this.printCollectedResults();*/
                    this.showVideo = false;
                    this.stopQuagga();
                    this.chr.markForCheck();
                    /*setTimeout(() => {
                      this.stopQuagga();
                      this.chr.markForCheck();
                    });*/
                }
            }
        }
    };
    return ScanBarcodeComponent;
}());
export { ScanBarcodeComponent };
