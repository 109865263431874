<!--<div class="morph-button morph-button-modal morph-button-modal-3 morph-button-fixed"> - signup
<div class="morph-button morph-button-inflow morph-button-inflow-1"> - subscribe
<div class="morph-button morph-button-inflow morph-button-inflow-2"> - share
<div class="morph-button morph-button-modal morph-button-modal-4 morph-button-fixed"> - video
<div class="morph-button morph-button-sidebar morph-button-fixed"> - sidenav
<div class="morph-button morph-button-overlay morph-button-fixed"> - info overlay-->
<div class="wsl-flex-middle-ng" #el
     [ngClass]="{
     'morph-button-inflow morph-button-inflow-1': options.type.inflow,
     'morph-button-modal morph-button-fixed': options.type.modal,
     'morph-button-sidebar': options.type.sidebar,
     'morph-button-overlay': options.type.overlay
}">
    <button class="btn btn-small" type="button"
            [@openBtn]="expandState"
            #button (click)="onToggle()">{{label}}<span *ngIf="withClone">{{cloneLabel}}</span></button>
    <div class="wsl-col" #content [@openContent]="expandState" (@openContent.done)="onTransitionEnd($event)">
        <!--<span class="morph-clone" *ngIf="withClone">{{cloneLabel}}01:43</span>-->
        <ng-content></ng-content>
    </div>
</div>
