import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { AllResources } from 'wsl-ek-core';
import { IWSLDeviceVerification } from '../../models/device-verification';
import {DeviceConfService} from '@app/device-conf/services/device-conf.service';
import {take, tap} from 'rxjs/operators';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';

@Component({
  selector: '[wsl-device-verification-row]',
  templateUrl: './device-verification-row.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceVerificationRowComponent implements OnInit, OnChanges {
  @Input() device: IWSLDeviceVerification;
  @Input() confs: IWSLDataConf[] = [];
  @Input() showObject = true;
  @Input() showRoom = true;

  resource = null;
  constructor() {
  }

  ngOnInit() {
    this.device.id = this.device.device_id;
    this.defineResource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.confs) {
      this.defineResource();
    }
  }

  /* get resources() {
     return AllResources.filter(r => this.device.resources ? this.device.resources.includes(r.id) : false);
   }*/

  private defineResource() {
    if (!!this.device.sensor_id) {
      const conf = this.confs.find(c => c.id === this.device.dconf_ext_id);
      this.resource = conf ? AllResources.find(r => r.id === conf.resource_id) : null;
    }
  }
}
