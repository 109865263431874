import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DeviceType } from 'wsl-device';
import { MomentDBDateFormat, WSLRouterHelperService, WSLUtils } from 'wsl-core';
import { ActivatedRoute } from '@angular/router';
import { AllResources, ResourceType, ResourceVariableType } from 'wsl-ek-core';
import { DeviceModelService } from '@app/device/services/device-model.service';
import { DeviceExecutionService } from '@app/device/services/device-execution.service';
import { WSLMaterializeHelper } from 'wsl-shared';
import * as moment from 'moment';
var MAX_V_FOR_HEAT = 2;
var MAX_T_FOR_HEAT = 3; // one for external cold water
var MAX_P_FOR_HEAT = 2;
var MAX_V_FOR_HOT = 2;
var MAX_T_FOR_HOT = 2;
var MAX_P_FOR_HOT = 2;
var MAX_V_FOR_COLD = 1;
var MAX_T_FOR_COLD = 0;
var MAX_P_FOR_COLD = 1;
var SCHEMA_NO_RESOURCES = -1;
var SCHEMA_WITH_MAGNETS = -2;
var AddDeviceSetupMeteringNodeComponent = /** @class */ (function () {
    function AddDeviceSetupMeteringNodeComponent(route) {
        this.route = route;
        this.disabledSensor = true;
        this.showNext = true;
        this.showValues = false;
        this.changeOnlyValues = false;
        this.selectSchema = new EventEmitter();
        this.setupMeteringNode = new EventEmitter();
        this.dmodels = [];
        this.dexecutions = [];
        this.rvariables = [];
        this.rvalues = [];
        this.runits = [];
        this.isCalc = false;
        this.deviceType = null;
        this.canEditEntry = false;
        this.internalResources = [];
        this.externalResources = [];
        this.availableSchemas = { single: [], double: [], triple: [] };
        this.selectedSchema = null;
        this.availableSensors = [];
        this.inputs = [];
        this.inputClass = 'icon-cross wsl-gray';
        this.input = null;
        this.showInput = false;
        this.inputsFilled = false;
    }
    AddDeviceSetupMeteringNodeComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.dmodels = data.dmodels;
        this.dexecutions = data.dexecutions;
        this.rvariables = data.resourceVariables;
        this.rvalues = data.resourceValues;
        this.runits = data.resourceUnits;
        this.define();
    };
    AddDeviceSetupMeteringNodeComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device) {
            this.define();
        }
        if (changes.sensors) {
            if (this.showInput) {
                this.input = null;
                this.showInput = false;
            }
            this.setSensorsToInputs();
            this.defineAvailableSensors();
        }
        if (changes.confGroups && this.confGroups && this.confGroups.length > 0 &&
            this.availableSchemas.single.concat(this.availableSchemas.double, this.availableSchemas.triple).length === 0) {
            this.defineSchemas();
        }
        if (changes.values) {
            this.fillValues();
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    AddDeviceSetupMeteringNodeComponent.prototype.onShowInput = function (input) {
        this.input = tslib_1.__assign({}, input);
        this.defineAvailableSensors();
        this.showInput = true;
    };
    AddDeviceSetupMeteringNodeComponent.prototype.onSaveInput = function (input) {
        var inputIndex = this.inputs.findIndex(function (i) { return i.id === input.id; });
        if (inputIndex > -1) {
            this.inputs[inputIndex] = tslib_1.__assign({}, this.inputs[inputIndex], { sensor_id: input.sensor_id, serial_num: input.serial_num, date_next: input.date_next, impulse_weight: input.impulse_weight, entry: input.entry, resource_id: input.resource_id, resource: AllResources.find(function (r) { return r.id === input.resource_id; }), resource_inx: input.resource_inx, rvalue_id: input.rvalue_id, rvalue: this.rvalues.find(function (r) { return r.id === input.rvalue_id; }), unit: this.runits.find(function (r) { return r.rvariable_id === input.rvariable_id && r.multiplier === '1'; }), value_beg: input.value_beg });
            this.inputs = this.inputs.slice(0);
        }
        this.onHideInput();
        if (!this.showNext) {
            this.onSetupMeteringNode();
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.onDeleteInput = function (input) {
        var inputIndex = this.inputs.findIndex(function (i) { return i.id === input.id; });
        if (inputIndex > -1) {
            this.inputs[inputIndex] = tslib_1.__assign({}, this.inputs[inputIndex], { rvalue_id: null, rvalue: null, unit: null, sensor_id: null, serial_num: null, date_next: null, impulse_weight: null, value_beg: null });
            this.inputs = this.inputs.slice(0);
            this.input = this.inputs[inputIndex];
        }
        this.defineAvailableSensors();
        if (!this.showNext) {
            this.onSetupMeteringNode();
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.onHideInput = function () {
        this.input = null;
        this.showInput = false;
    };
    AddDeviceSetupMeteringNodeComponent.prototype.onSetupMeteringNode = function () {
        var _this = this;
        if (!this.selectedSchema) {
            return;
        }
        var filledInputs = this.inputs.filter(function (input) { return !!input.serial_num; });
        if (DeviceModelService.mustSetupSensor(DeviceModelService.getModel(this.device, this.dmodels))) {
            // для карат-926 необходимо настроить сенсор
            if (filledInputs.length === 0) {
                if (!this.disabled) {
                    WSLMaterializeHelper.toast({ html: 'Необходимо настроить вход!' });
                }
                return;
            }
        }
        else if (!this.isCalc) {
            if (this.selectedSchema.resources.length === 0) {
                filledInputs = [];
            }
            else if (filledInputs.length !== this.selectedSchema.resources.filter(function (res) { return _this.externalResources.includes(res.id); }).length) {
                //  WSLMaterializeHelper.toast({html: 'Необходимо настроить все входы ПУ!'});
                return;
            }
        }
        if (filledInputs.some(function (i) { return !i.entry || !i.date_next || !i.resource_inx || !i.rvalue_id; })) {
            if (!this.disabled) {
                WSLMaterializeHelper.toast({ html: 'Заполните данные датчиков' });
            }
            return;
        }
        if (filledInputs.some(function (i) { return !!filledInputs.find(function (j) { return j.id !== i.id && i.entry === j.entry; }); })) {
            if (!this.disabled) {
                WSLMaterializeHelper.toast({ html: 'Датчики должны быть подключены на разные входы' });
            }
            return;
        }
        if (filledInputs.some(function (i) { return !!filledInputs.find(function (j) { return j.id !== i.id && i.rvalue_id === j.rvalue_id; }); })) {
            if (!this.disabled) {
                WSLMaterializeHelper.toast({ html: 'Датчики должны быть настроены к разным параметрам' });
            }
            return;
        }
        this.setupMeteringNode.emit({ inputs: filledInputs, schema: this.selectedSchema });
    };
    AddDeviceSetupMeteringNodeComponent.prototype.onSelectSchema = function (schema) {
        if (this.disabledSensor || (this.selectedSchema && this.selectedSchema.id === schema.id)) {
            return;
        }
        this.selectedSchema = tslib_1.__assign({}, schema);
        if (this.selectedSchema.resources.length === 1 && this.selectedSchema.resources[0].id === SCHEMA_NO_RESOURCES) {
            this.selectedSchema.resources = [];
        }
        this.selectSchema.emit(this.selectedSchema);
        this.onHideInput();
        this.defineInputs();
    };
    AddDeviceSetupMeteringNodeComponent.prototype.define = function () {
        var _this = this;
        this.dmodel = DeviceModelService.getModel(this.device, this.dmodels);
        this.isCalc = DeviceModelService.isCalc(this.dmodel);
        this.deviceType = DeviceModelService.getDeviceType(this.dmodel);
        this.canEditEntry = this.isCalc;
        this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
        if (this.dexecution && !!this.confGroups) {
            this.internalResources = AllResources.filter(function (r) { return _this.dexecution.resources_in.includes(r.id); });
            this.externalResources = this.dexecution.resources.filter(function (r) { return !_this.dexecution.resources_in.includes(r); });
        }
        if (this.inputs.length === 0 && !!this.selectedSchema) {
            this.defineInputs();
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineSchemas = function () {
        var _this = this;
        if (!this.confGroups || this.confGroups.length === 0 || !this.device) {
            return;
        }
        var schemaId = 1;
        this.availableSchemas = { single: [], double: [], triple: [] };
        this.confGroups
            .filter(function (group) { return group.id === _this.device.group_id ||
            (_this.device.communicators.filter(function (c) { return !!c.vr; }).length === 0 && (!group.protocols || group.protocols.length === 0)) ||
            (group.protocols && WSLUtils.intersection(group.protocols, _this.device.communicators.map(function (c) { return c.vr; })).length > 0); })
            .forEach(function (group) {
            if (group.ext && group.ext.resources) {
                if (group.ext.resources.length === 1) {
                    if (!_this.availableSchemas.single.some(function (schema) { return schema.resources[0].id === group.ext.resources[0].resource_id; })) {
                        _this.availableSchemas.single.push({
                            id: schemaId,
                            resources: group.ext.resources
                                .map(function (res) { return AllResources.find(function (r) { return r.id === res.resource_id; }); })
                        });
                        schemaId++;
                    }
                }
                else if (group.ext.resources.length === 0) {
                    if (!_this.availableSchemas.single.some(function (schema) { return schema.resources[0].id === SCHEMA_NO_RESOURCES; })) {
                        _this.availableSchemas.single.push({
                            id: schemaId,
                            resources: [{ id: SCHEMA_NO_RESOURCES, name: '', icon: 'icon-cross wsl-black' }]
                        });
                        schemaId++;
                    }
                }
                else if (group.ext.resources.length === 2) {
                    if (!_this.schemaExist(_this.availableSchemas.double, group)) {
                        _this.availableSchemas.double.push({
                            id: schemaId,
                            resources: group.ext.resources.map(function (res) { return AllResources.find(function (r) { return r.id === res.resource_id; }); })
                        });
                        schemaId++;
                    }
                }
                else if (group.ext.resources.length > 2) {
                    if (!_this.schemaExist(_this.availableSchemas.triple, group)) {
                        _this.availableSchemas.triple.push({
                            id: schemaId,
                            resources: group.ext.resources.map(function (res) { return AllResources.find(function (r) { return r.id === res.resource_id; }); })
                        });
                        schemaId++;
                    }
                }
            }
        });
        // @todo magnet control
        /* if (this.confGroups.some(g => g.ext && g.ext.magnet_control)) {
           this.availableSchemas.single.push({
             id: schemaId,
             resources: [{id: SCHEMA_WITH_MAGNETS, name: '', icon: 'icon-magnet wsl-black'}]
           });
           schemaId++;
         }*/
        if (schemaId === 2) {
            // только одна схема доступна для выбора - выберем ее сразу!
            if (this.availableSchemas.single.length > 0) {
                this.onSelectSchema(this.availableSchemas.single[0]);
            }
            else if (this.availableSchemas.double.length > 0) {
                this.onSelectSchema(this.availableSchemas.double[0]);
            }
            else if (this.availableSchemas.triple.length > 0) {
                this.onSelectSchema(this.availableSchemas.triple[0]);
            }
        }
        else if (!!this.setupSchemaId) {
            // вернулись назад, выберем схему, которые выбрали раньше
            var schema = this.availableSchemas.single.concat(this.availableSchemas.double, this.availableSchemas.triple).find(function (s) { return s.id === _this.setupSchemaId; });
            if (schema) {
                this.onSelectSchema(schema);
            }
        }
        else if (!!this.device && this.device.group_id) {
            // в приборе выбрана конфигурация - найдем подходящую схему
            var group_1 = this.confGroups.find(function (g) { return g.id === _this.device.group_id; });
            if (group_1) {
                var schema = this.availableSchemas.single.concat(this.availableSchemas.double, this.availableSchemas.triple).filter(function (s) { return s.resources.length >= group_1.ext.resources.length; })
                    .find(function (s) { return s.resources
                    .map(function (r) { return r.id; })
                    .reduce(function (prev, cur, index) { return prev && group_1.ext.resources[index] && cur === group_1.ext.resources[index].resource_id; }, true); });
                if (schema) {
                    this.onSelectSchema(schema);
                }
                else if (group_1.ext.resources.length === 0 && this.availableSchemas.single.some(function (s) { return s.resources[0].id === SCHEMA_NO_RESOURCES; })) {
                    this.onSelectSchema(this.availableSchemas.single.find(function (s) { return s.resources[0].id === SCHEMA_NO_RESOURCES; }));
                }
            }
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.schemaExist = function (schemas, group) {
        return schemas.some(function (schema) { return schema.resources
            .map(function (r) { return r.id; })
            .reduce(function (prev, cur, index) { return prev && group.ext.resources[index] && cur === group.ext.resources[index].resource_id; }, true); });
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineInputs = function () {
        var _this = this;
        this.inputs = [];
        if (!this.selectedSchema || !this.dexecution) {
            return;
        }
        // если схема с 4 хвс - то выбор только из хвс!
        var resources = Array.from(new Set(this.selectedSchema.resources.map(function (r) { return r.id; })));
        this.selectedSchema.resources.forEach(function (resource) {
            switch (resource.id) {
                case ResourceType.heat:
                    if (_this.isCalc) {
                        _this.defineHeatInputs(resource);
                    }
                    else {
                        /*
                          @todo вроде бы к 927 можно что-то подключить, но его пока нет в производстве
                          для остальных вообще ничего
                         */
                    }
                    break;
                case ResourceType.hot:
                    if (_this.isCalc) {
                        _this.defineHotInputs(resource);
                    }
                    else {
                        /*
                          для 926, 526, 213, 223 - можно подключить только вертушки V
                          для 927 - можно подключить как V так и любой датчик (как у 306/7/8)
                         */
                        if (_this.deviceType.id === DeviceType.node) {
                            _this.defineHotInputs(resource);
                        }
                        else {
                            _this.inputs.push(_this.defineInputForResource(resource.id, ResourceVariableType.v));
                        }
                    }
                    break;
                case ResourceType.cold:
                    if (_this.isCalc) {
                        _this.defineColdInputs(resource);
                    }
                    else {
                        /*
                          для 926, 526, 213, 223 - можно подключить только вертушки V
                          для 927 - можно подключить как V так и любой датчик (как у 306/7/8)
                         */
                        if (_this.deviceType.id === DeviceType.node) {
                            _this.defineHotInputs(resource);
                        }
                        else {
                            _this.inputs.push(_this.defineInputForResource(resource.id, ResourceVariableType.v));
                        }
                    }
                    break;
                default:
                    // газ и электричество как импульс можно, но хз
                    break;
            }
        });
        this.setSensorsToInputs();
        this.defineAvailableSensors();
        /*if (this.setupInputs && this.setupInputs.length > 0) {
          this.fillInputs();
        }*/
        if (this.values) {
            this.fillValues();
        }
        if (this.changeOnlyValues) {
            this.inputs = this.inputs.filter(function (i) { return !!i.sensor_id; });
        }
        this.inputs.sort(function (a, b) { return a.entry < b.entry ? -1 : 1; });
        if (this.inputs.length === 1) {
            this.onShowInput(this.inputs[0]);
        }
        this.onSetupMeteringNode();
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineAvailableSensors = function () {
        var _this = this;
        this.availableSensors = this.sensors ? this.sensors.filter(function (s) { return !_this.inputs.find(function (inp) { return inp.sensor_id === s.id; }); }) : [];
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineColdInputs = function (resource) {
        for (var i = 0; i < Math.min(MAX_V_FOR_COLD, this.dexecution.v); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
        }
        for (var i = 0; i < Math.min(MAX_P_FOR_COLD, this.dexecution.p); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.p));
        }
        for (var i = 0; i < Math.min(MAX_T_FOR_COLD, this.dexecution.t); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.t));
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineHeatInputs = function (resource) {
        for (var i = 0; i < Math.min(MAX_V_FOR_HEAT, this.dexecution.v); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
        }
        for (var i = 0; i < Math.min(MAX_P_FOR_HEAT, this.dexecution.p); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.p));
        }
        for (var i = 0; i < Math.min(MAX_T_FOR_HEAT, this.dexecution.t); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.t));
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineHotInputs = function (resource) {
        for (var i = 0; i < Math.min(MAX_V_FOR_HOT, this.dexecution.v); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
        }
        for (var i = 0; i < Math.min(MAX_P_FOR_HOT, this.dexecution.p); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.p));
        }
        for (var i = 0; i < Math.min(MAX_T_FOR_HOT, this.dexecution.t); i++) {
            this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.t));
        }
    };
    AddDeviceSetupMeteringNodeComponent.prototype.defineInputForResource = function (resource_id, rvariable_id) {
        return {
            id: this.inputs.length + 1,
            rvariable_id: rvariable_id,
            rvariable: this.rvariables.find(function (r) { return r.id === rvariable_id; }),
            rvalue_id: null,
            rvalue: null,
            unit: null,
            resource_inx: 1,
            resource_id: resource_id,
            resource: AllResources.find(function (r) { return r.id === resource_id; }),
            entry: this.inputs.length + 1,
            sensor_id: null,
            serial_num: null,
            date_next: null,
            impulse_weight: null,
            device_id: this.device.id,
            dconf_ext_id: null,
            requiredValue: this.showValues && !this.isCalc,
            value_beg: null
        };
    };
    AddDeviceSetupMeteringNodeComponent.prototype.errorInput = function (input) {
        return !this.disabled && !!input.serial_num && (!input.entry || !input.date_next || moment(input.date_next, MomentDBDateFormat).isSameOrBefore(moment(), 'day') ||
            !input.resource_inx || !input.rvalue_id || (input.requiredValue && input.value_beg === null) ||
            !!this.inputs.find(function (j) { return j.id !== input.id && !!j.serial_num && (j.rvalue_id === input.rvalue_id || input.entry === j.entry); }));
    };
    AddDeviceSetupMeteringNodeComponent.prototype.successInput = function (input) {
        return !!input.serial_num && !this.errorInput(input);
    };
    /*private fillInputs() {
      if (!this.selectedSchema) {
        return;
      }
      this.setupInputs
        .forEach(si => {
          let id =  this.inputs.findIndex(i => this.isEqualInputs(i, si));
          if (id < 0) {
            id = this.inputs.findIndex(i => this.isSimilarFreeInput(i, si));
          }
          if (id > -1) {
            this.inputs[id] = {
              ...this.inputs[id],
              rvalue_id: si.rvalue_id,
              entry: si.entry,
              resource_inx: si.resource_inx,
              sensor_id: si.sensor_id,
              serial_num: si.serial_num,
              date_next: si.date_next,
              impulse_weight: si.impulse_weight,
              value_beg: si.value_beg,
              rvalue: this.rvalues.find(r => r.id === si.rvalue_id),
              unit: this.runits.find(r => r.rvariable_id === si.rvariable_id && r.multiplier === '1')
            };
          }
        });
      this.inputsFilled = true;
    }*/
    AddDeviceSetupMeteringNodeComponent.prototype.setSensorsToInputs = function () {
        var _this = this;
        if (!this.sensors || this.sensors.length === 0) {
            return;
        }
        this.sensors.forEach(function (sensor) {
            var id = -1;
            var rvalue = _this.rvalues.find(function (rv) { return rv.id === sensor.rvalue_id; });
            if (!!rvalue && sensor.rvalue_id) {
                id = _this.inputs.findIndex(function (i) { return _this.isEqualInputs(i, tslib_1.__assign({}, sensor, { sensor_id: sensor.id, rvariable_id: rvalue.rvariable_id })); });
                if (id < 0) {
                    id = _this.inputs.findIndex(function (i) { return _this.isSimilarFreeInput(i, tslib_1.__assign({}, sensor, { sensor_id: sensor.id, rvariable_id: rvalue.rvariable_id })); });
                }
            }
            else if (_this.inputs.length === 1) {
                // for karat 926 from EPassport
                id = 0;
            }
            if (id > -1) {
                //     const availableRvalues = this.rvalues.filter(r => r.resource_id === sensor.resource_id);
                _this.inputs[id] = tslib_1.__assign({}, _this.inputs[id], { sensor_id: sensor.id, serial_num: sensor.serial_num, rvalue_id: sensor.rvalue_id, entry: sensor.entry, resource_inx: sensor.resource_inx, date_next: sensor.date_next, impulse_weight: sensor.impulse_weight, value_beg: null, // +sensor.value || +sensor.value_beg
                    rvalue: rvalue, unit: rvalue ? _this.runits.find(function (r) { return r.rvariable_id === rvalue.rvariable_id && r.multiplier === '1'; }) : null });
            }
        });
        this.inputs = this.inputs.filter(function (i) { return !i.sensor_id || (i.sensor_id && _this.sensors && !!_this.sensors.find(function (s) { return s.id === i.sensor_id; })); });
    };
    AddDeviceSetupMeteringNodeComponent.prototype.fillValues = function () {
        var _this = this;
        if (!this.values || this.values.length === 0 || this.inputs.length === 0) {
            return;
        }
        this.values.forEach(function (v) {
            var id = _this.inputs.findIndex(function (i) { return _this.isEqualInputs(i, v); });
            if (id < 0) {
                id = _this.inputs.findIndex(function (i) { return _this.isSimilarFreeInput(i, v); });
            }
            if (id < 0 && _this.values.length === 1 && _this.inputs.length === 1) {
                id = 0;
                if (!v.serial_num && v.sensor_id) {
                    var sensor = _this.sensors && _this.sensors.find(function (s) { return s.id === v.sensor_id; });
                    if (sensor) {
                        v = tslib_1.__assign({}, v, { serial_num: sensor.serial_num });
                    }
                }
            }
            if (id > -1) {
                _this.inputs[id] = tslib_1.__assign({}, _this.inputs[id], v, { rvalue: _this.rvalues.find(function (r) { return r.id === v.rvalue_id; }), unit: _this.runits.find(function (r) { return r.rvariable_id === v.rvariable_id && r.multiplier === '1'; }) });
            }
        });
    };
    AddDeviceSetupMeteringNodeComponent.prototype.isEqualInputs = function (a, b) {
        return a.sensor_id === b.sensor_id ||
            (!a.sensor_id && ((a.serial_num === b.serial_num && a.resource_inx === b.resource_inx && a.rvalue_id === b.rvalue_id)));
    };
    AddDeviceSetupMeteringNodeComponent.prototype.isSimilarFreeInput = function (a, b) {
        return !a.sensor_id && !a.serial_num && a.rvariable_id === b.rvariable_id && a.resource_id === b.resource_id;
    };
    return AddDeviceSetupMeteringNodeComponent;
}());
export { AddDeviceSetupMeteringNodeComponent };
