export enum DeviceActionButtonType {
  input_device = 5,
  verify_device = 6,
  repair_device = 7,
  correct_device = 8,
  output_device = 9,
  hide_device = 10,
  revise_data = 11
}

export const InputDeviceButtonType = {
  id: DeviceActionButtonType.input_device,
  label: 'Ввести в эксплуатацию',
  visible: true,
  icon: 'icon-enter-right',
  classes: 'wsl-bg-white_hover wsl-primary-color',
};

export const VerifyDeviceButtonType = {
  id: DeviceActionButtonType.verify_device,
  label: 'Поверка',
  visible: true,
  icon: 'icon-calendar-31',
  classes: 'wsl-bg-white_hover wsl-blue',
};

export const RepairDeviceButtonType = {
  id: DeviceActionButtonType.repair_device,
  label: 'Ремонт',
  visible: true,
  icon: 'icon-wrench',
  classes: 'wsl-bg-white_hover wsl-gray',
};

export const CorrectionDeviceButtonType = {
  id: DeviceActionButtonType.correct_device,
  label: 'Коррекция значений',
  visible: true,
  icon: 'icon-transmission',
  classes: 'wsl-bg-white_hover wsl-green',
};

export const OutputDeviceButtonType = {
  id: DeviceActionButtonType.output_device,
  label: 'Выбытие',
  visible: true,
  icon: 'icon-exit-right',
  classes: 'wsl-bg-white_hover wsl-black',
};

export const HideDeviceButtonType = {
  id: DeviceActionButtonType.hide_device,
  label: 'Скрыть навсегда',
  visible: true,
  icon: 'icon-trash2',
  classes: 'wsl-bg-white_hover wsl-red',
};
