import * as tslib_1 from "tslib";
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateFormat, MomentDBDateFormat, WSLFormHelper, WSLFormValidators, WSLRouterHelperService, WSLUtils } from 'wsl-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import * as moment from 'moment';
import { AllResources, ResourceVariableType } from 'wsl-ek-core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var DeviceInputSetupFormComponent = /** @class */ (function () {
    function DeviceInputSetupFormComponent(route, chr) {
        this.route = route;
        this.chr = chr;
        this.prefix = WSLUtils.randomID();
        this.disabled = true;
        this.showValue = false;
        this.changeOnlyValue = false;
        this.save = new EventEmitter();
        this.delete = new EventEmitter();
        this.cancel = new EventEmitter();
        this.form = new FormGroup({
            id: new FormControl(null),
            resource_id: new FormControl({ value: null, disabled: true }, [Validators.required]),
            resource_inx: new FormControl(1, [Validators.required]),
            rvalue_id: new FormControl(null, [Validators.required]),
            entry: new FormControl(null, [Validators.required]),
            sensor_id: new FormControl(null),
            // rvariable_id: new FormControl(null)
            serial_num: new FormControl(null, [Validators.required]),
            date_next: new FormControl(null, [Validators.required]),
            value_beg: new FormControl(null),
            impulse_weight: new FormControl(null)
        });
        this.entries = Array.from(new Array(20), function (val, index) { return ({ id: index + 1, name: index + 1 }); });
        this.resourceInxs = Array.from(new Array(3), function (val, index) { return ({ id: index + 1, name: index + 1 }); });
        this.weights = [{ id: '0.1', name: 0.1 }, { id: '1', name: 1 }, { id: '10', name: 10 }, { id: '100', name: 100 }];
        this.maxDate = moment().add(15, 'years').format(MomentDateFormat);
        this.minDate = moment().subtract(5, 'years').format(MomentDateFormat);
        this.resources = [];
        this.showImpulseWeight = false;
        this.sensorsVisible = false;
        this.selectedSensor = null;
        this.availableRvalues = [];
        this.rvariables = [];
        this.rvalues = [];
        this.runits = [];
        this.ngUnsubscribe = new Subject();
    }
    DeviceInputSetupFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.rvariables = data.resourceVariables;
        this.rvalues = data.resourceValues;
        this.runits = data.resourceUnits;
        this.fillForm();
        this.form.get('rvalue_id').valueChanges
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (val) {
            var rval = _this.rvalues.find(function (rv) { return rv.id === val; });
            _this.runit = rval ? _this.runits.find(function (ru) { return ru.rvariable_id === rval.rvariable_id && ru.multiplier === '1'; }) : null;
        });
    };
    DeviceInputSetupFormComponent.prototype.ngOnChanges = function (changes) {
        if (changes.input) {
            this.fillForm();
        }
        if (changes.canEditEntry) {
            if (this.canEditEntry && !this.disabled) {
                this.form.get('entry').enable();
            }
            else if (!!this.form.get('entry').value) {
                this.form.get('entry').disable();
            }
        }
        if (changes.disabled) {
            if (this.disabled) {
                this.form.disable();
            }
        }
    };
    DeviceInputSetupFormComponent.prototype.ngOnDestroy = function () {
        if (this.MDropdown) {
            this.MDropdown.close();
            this.MDropdown.destroy();
        }
        this.ngUnsubscribe.next(null);
        this.ngUnsubscribe.complete();
    };
    DeviceInputSetupFormComponent.prototype.onSave = function () {
        if (this.disabled) {
            WSLMaterializeHelper.toast({ html: 'Доступ запрещен' });
            return;
        }
        if (this.form.valid) {
            this.save.emit(tslib_1.__assign({}, this.input, this.form.getRawValue(), { date_next: moment(this.form.get('date_next').value, MomentDateFormat).format(MomentDBDateFormat) }));
        }
        else {
            if (this.form.get('serial_num').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите серийный номер' });
            }
            else if (this.form.get('date_next').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите дату поверки' });
            }
            else if (this.form.get('impulse_weight').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите вес импульса' });
            }
            else if (this.form.get('entry').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите выход' });
            }
            else if (this.form.get('resource_id').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите ресурс' });
            }
            else if (this.form.get('resource_inx').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите номер ресурса' });
            }
            else if (this.form.get('rvalue_id').invalid) {
                WSLMaterializeHelper.toast({ html: 'Укажите параметр' });
            }
        }
    };
    DeviceInputSetupFormComponent.prototype.onCancel = function () {
        this.cancel.emit();
    };
    DeviceInputSetupFormComponent.prototype.onDelete = function () {
        this.delete.emit(this.input);
    };
    DeviceInputSetupFormComponent.prototype.fillForm = function () {
        var _this = this;
        this.form.reset();
        WSLFormHelper.fillForm(this.form, this.input);
        this.resources = AllResources.filter(function (r) { return r.id === _this.input.resource_id; });
        this.availableRvalues = this.rvalues
            .filter(function (r) { return r.resource_id === _this.input.resource_id && r.rvariable_id === _this.input.rvariable.id; })
            .sort(function (a, b) { return a.sort < b.sort ? -1 : 1; });
        var rval = this.rvalues.find(function (rv) { return rv.id === _this.input.rvalue_id; });
        this.runit = rval ? this.runits.find(function (ru) { return ru.rvariable_id === rval.rvariable_id && ru.multiplier === '1'; }) : null;
        if (!this.input.rvalue_id && this.availableRvalues.length === 1) {
            this.form.get('rvalue_id').setValue(this.availableRvalues[0].id);
        }
        this.showImpulseWeight = [ResourceVariableType.q, ResourceVariableType.v, ResourceVariableType.e].includes(this.input.rvariable.id);
        if (this.showImpulseWeight) {
            this.form.get('impulse_weight').setValidators([Validators.required]);
            if (this.form.get('impulse_weight').value === null && this.input.rvariable.id === ResourceVariableType.v) {
                this.form.get('impulse_weight').setValue(this.weights[1].id, { emitEvent: false });
            }
        }
        else {
            this.form.get('impulse_weight').clearValidators();
        }
        this.form.get('impulse_weight').updateValueAndValidity();
        /*if (!!this.input.sensor_id) {
          this.form.get('serial_num').disable();
        } else {
          this.form.get('serial_num').enable();
        }*/
        if (this.input.date_next) {
            this.form.get('date_next').setValue(moment(this.input.date_next, MomentDBDateFormat).format(MomentDateFormat), { emitEvent: false });
        }
        if (this.input.requiredValue) {
            this.form.get('value_beg').setValidators([Validators.required, WSLFormValidators.inputMeteringsValidator]);
        }
        if (this.changeOnlyValue) {
            this.form.disable();
            if (!this.disabled) {
                this.form.get('value_beg').enable();
            }
        }
        this.form.markAsPristine();
        this.form.markAsUntouched();
    };
    DeviceInputSetupFormComponent.prototype.onSelectResource = function (resource_id) {
        this.form.get('resource_id').setValue(resource_id);
        this.form.get('resource_id').markAsDirty();
    };
    DeviceInputSetupFormComponent.prototype.onShowSensors = function () {
        if (!!this.availableSensors && this.availableSensors.length > 0) {
            this.sensorsVisible = true;
            this.selectedSensor = null;
            if (!this.MDropdown) {
                this.initDropdown();
            }
            this.MDropdown.open();
        }
    };
    DeviceInputSetupFormComponent.prototype.onHideSensors = function () {
        this.sensorsVisible = false;
        this.MDropdown.close();
    };
    DeviceInputSetupFormComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    DeviceInputSetupFormComponent.prototype.onSelectSensor = function (sensor) {
        this.selectedSensor = tslib_1.__assign({}, sensor);
    };
    DeviceInputSetupFormComponent.prototype.onFillSensor = function () {
        var _this = this;
        if (!!this.selectedSensor) {
            this.form.get('sensor_id').setValue(this.selectedSensor.id);
            this.form.get('serial_num').setValue(this.selectedSensor.serial_num);
            if (this.selectedSensor.impulse_weight) {
                this.form.get('impulse_weight').setValue(this.selectedSensor.impulse_weight);
            }
            if (this.selectedSensor.date_next) {
                this.form.get('date_next').setValue(this.selectedSensor.date_next);
            }
            if (this.selectedSensor.rvalue_id && this.availableRvalues.map(function (r) { return r.id; }).includes(this.selectedSensor.rvalue_id)) {
                this.form.get('rvalue_id').setValue(this.selectedSensor.rvalue_id);
            }
            this.availableSensors = this.availableSensors.filter(function (s) { return s.id !== _this.selectedSensor.id; });
        }
        this.onHideSensors();
    };
    DeviceInputSetupFormComponent.prototype.initDropdown = function () {
        var self = this;
        this.MDropdown = WSLMaterializeHelper.initDropdown("." + this.prefix + "_dropdown_trigger", {
            constrainWidth: false,
            coverTrigger: false,
            closeOnClick: false,
            onCloseEnd: function () {
                self.sensorsVisible = false;
                self.chr.markForCheck();
                //  self.showVariants = false;
                //  self.chr.markForCheck();
            }
        });
    };
    return DeviceInputSetupFormComponent;
}());
export { DeviceInputSetupFormComponent };
