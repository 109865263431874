import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {IWSLCommunicator} from 'wsl-device';
import {IWSLAsyncErrors} from 'wsl-core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {WSLMaterializeHelper} from 'wsl-shared';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ConnectionType} from '@app/communicator/enums/connection-type.enum';

@Component({
  selector: 'wsl-add-device-select-communicator',
  templateUrl: './add-device-select-communicator.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceSelectCommunicatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() objectId: number;
  @Input() communicators: IWSLCommunicator[];
  @Input() pending: boolean;
  @Input() asyncErrors: IWSLAsyncErrors;
  @Input() disabledCommunicator = true;

  @Output() visibleAddCommunicator = new EventEmitter<boolean>();
  @Output() addCommunicator = new EventEmitter<IWSLCommunicator>();
  @Output() selectCommunicator = new EventEmitter<{communicator_id: number, modbus_addr: string}>();

  showAddCommunicator = false;
  showModbus = false;

  form = new FormGroup({
    communicator_id: new FormControl(null, [Validators.required]),
    modbus_addr: new FormControl(null)
  });

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private chr: ChangeDetectorRef) { }

  ngOnInit() {
    this.form.get('communicator_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap(id => {
          const com = this.communicators.find(c => c.id === id);
          if (com && com.network && [ConnectionType.tcp, ConnectionType.tcp_gprs, ConnectionType.gprs].includes(com.network.channel_id)) {
            this.form.get('modbus_addr').setValidators([Validators.required]);
            this.showModbus = true;
          } else {
            this.form.get('modbus_addr').clearValidators();
            this.showModbus = false;
          }
          this.chr.markForCheck();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.communicators) {
      if (this.showAddCommunicator) {
        this.onCancelAddCommunicator();
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onShowAddCommunicator() {
    this.showAddCommunicator = true;
    this.visibleAddCommunicator.emit(true);
  }

  onSelectCommunicator() {
    if (this.form.valid) {
      this.selectCommunicator.emit(this.form.getRawValue());
    } else {
      WSLMaterializeHelper.toast({html: 'Выберите коммуникатор'});
    }
  }

  onAddCommunicator(communicator: IWSLCommunicator) {
    this.addCommunicator.emit(communicator);
  }

  onCancelAddCommunicator() {
    this.showAddCommunicator = false;
    this.visibleAddCommunicator.emit(false);
  }
}
