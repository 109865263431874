export enum LoRaWANProtocol {
  '1.0.0' = 1,
  '1.0.1' = 2,
  '1.0.2' = 3,
  '1.1.0' = 4
}

export const LoRaWANProtocols = [
  {id: LoRaWANProtocol['1.0.0'], name: LoRaWANProtocol[LoRaWANProtocol['1.0.0']]},
  {id: LoRaWANProtocol['1.0.1'], name: LoRaWANProtocol[LoRaWANProtocol['1.0.1']]},
  {id: LoRaWANProtocol['1.0.2'], name: LoRaWANProtocol[LoRaWANProtocol['1.0.2']]},
  {id: LoRaWANProtocol['1.1.0'], name: LoRaWANProtocol[LoRaWANProtocol['1.1.0']]},
];
