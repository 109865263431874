import { EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { WSLRouterHelperService, WSLUtils } from 'wsl-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { DeviceModelService } from '@app/device/services/device-model.service';
import { DeviceExecutionService } from '@app/device/services/device-execution.service';
import { AllResources } from 'wsl-ek-core';
import { ActivatedRoute } from '@angular/router';
var AddDeviceSelectConfigurationComponent = /** @class */ (function () {
    function AddDeviceSelectConfigurationComponent(route) {
        this.route = route;
        this.disabled = true;
        this.showBack = false;
        // @Output() loadConfGroups = new EventEmitter<IWSLDeviceConfGroupFilter>();
        this.loadConfs = new EventEmitter();
        this.loadConfData = new EventEmitter();
        this.loadFileIndex = new EventEmitter();
        this.selectConfiguration = new EventEmitter();
        this.back = new EventEmitter();
        this.suitableGroups = [];
        this.selectedGroup = null;
        this.selectedFileIndex = null;
        this.wrongSetup = false;
        this.selectConfigurationClicked = false;
        this.isCalc = false;
        this.internalResources = [];
        this.externalResources = [];
        this.mustSelectFileIndex = false;
        this.dmodels = [];
        this.dexecutions = [];
    }
    AddDeviceSelectConfigurationComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.dmodels = data.dmodels;
        this.dexecutions = data.dexecutions;
        this.define();
        this.defineSuitableGroups();
    };
    AddDeviceSelectConfigurationComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device) {
            this.define();
        }
        if (changes.confGroups || changes.schema) {
            this.defineSuitableGroups();
        }
        if (changes.groupFileIndexes) {
            if (this.device && this.device.group_file_id) {
                this.selectedFileIndex = this.device.group_file_id;
            }
            else if (this.groupFileIndexes && this.groupFileIndexes.length === 1) {
                this.selectedFileIndex = this.groupFileIndexes[0].id;
            }
        }
        if (changes.inputs) {
            this.defineSetup();
        }
    };
    AddDeviceSelectConfigurationComponent.prototype.ngOnDestroy = function () {
        if (this.MModal) {
            this.MModal.close();
            this.MModal.destroy();
        }
    };
    AddDeviceSelectConfigurationComponent.prototype.defineSuitableGroups = function () {
        var _this = this;
        this.selectedGroup = null;
        this.suitableGroups = !!this.confGroups && !!this.device ?
            this.confGroups
                .filter(function (group) {
                return (_this.device.communicators.filter(function (c) { return !!c.vr; }).length === 0 && (!group.protocols || group.protocols.length === 0)) ||
                    (group.protocols && WSLUtils.intersection(group.protocols, _this.device.communicators.map(function (c) { return c.vr; })).length > 0);
            })
                .filter(function (group) {
                if (_this.schema) {
                    return group.ext.resources.length === _this.schema.resources.length &&
                        _this.schema.resources
                            .map(function (r) { return r.id; })
                            .reduce(function (prev, cur, index) { return prev && group.ext.resources[index] && cur === group.ext.resources[index].resource_id; }, true);
                }
                if (_this.device && _this.device.group_id) {
                    if (_this.deviceSensors) {
                        return group.id === _this.device.group_id ||
                            (group.ext.resources.length === _this.deviceSensors.length &&
                                WSLUtils.difference(_this.deviceSensors.map(function (s) { return s.resource_id; }), group.ext.resources.map(function (r) { return r.resource_id; })).length === 0);
                    }
                }
                return false;
            }) : [];
        if (this.suitableGroups.length > 0) {
            if (this.suitableGroups.length === 1) {
                this.onSelectGroup({ id: this.suitableGroups[0].id, checked: true });
            }
            else if (!!this.device.group_id) {
                if (!!this.suitableGroups.find(function (g) { return g.id === _this.device.group_id; })) {
                    this.onSelectGroup({ id: this.device.group_id, checked: true });
                }
            }
        }
    };
    AddDeviceSelectConfigurationComponent.prototype.onSelectGroup = function (_a) {
        var id = _a.id, checked = _a.checked;
        if (checked && this.selectedGroup !== id) {
            this.selectedGroup = id;
            var group = this.suitableGroups.find(function (g) { return g.id === id; });
            this.loadConfData.emit({ dconf_ids: group.dconf_ids });
            if (!this.device.individual) {
                this.loadFileIndex.emit({ group_id: this.selectedGroup });
            }
            this.defineSetup();
        }
        else if (!checked && this.selectedGroup === id) {
            this.selectedGroup = null;
        }
    };
    AddDeviceSelectConfigurationComponent.prototype.onSelectGroupFileIndex = function (_a) {
        var id = _a.id, checked = _a.checked;
        if (checked && this.selectedFileIndex !== id) {
            this.selectedFileIndex = id;
        }
        else if (!checked && this.selectedFileIndex === id) {
            this.selectedFileIndex = null;
        }
    };
    AddDeviceSelectConfigurationComponent.prototype.onShowGroupConf = function (group) {
        this.MModal = WSLMaterializeHelper.initModal('#add_device_conf_data', { dismissible: true });
        this.openModal();
        this.loadConfs.emit({ dconf_ids: group.dconf_ids });
        this.loadConfData.emit({ dconf_ids: group.dconf_ids });
    };
    AddDeviceSelectConfigurationComponent.prototype.openModal = function () {
        this.MModal.open();
    };
    AddDeviceSelectConfigurationComponent.prototype.closeModal = function () {
        this.MModal.close();
    };
    AddDeviceSelectConfigurationComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    AddDeviceSelectConfigurationComponent.prototype.onSelectConfiguration = function () {
        var _this = this;
        this.selectConfigurationClicked = true;
        this.defineSetup(true);
        if (this.wrongSetup) {
            return;
        }
        if (!!this.selectedGroup) {
            var group = this.suitableGroups.find(function (g) { return g.id === _this.selectedGroup; });
            var fileIndex = this.mustSelectFileIndex ? this.groupFileIndexes.find(function (f) { return f.id === _this.selectedFileIndex; }) : null;
            this.selectConfiguration.emit({ group: group, fileIndex: fileIndex });
        }
        else {
            WSLMaterializeHelper.toast({ html: 'Выберите конфигурацию ПУ' });
        }
    };
    AddDeviceSelectConfigurationComponent.prototype.define = function () {
        var _this = this;
        this.dmodel = DeviceModelService.getModel(this.device, this.dmodels);
        this.isCalc = DeviceModelService.isCalc(this.dmodel);
        this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
        if (this.dexecution && !!this.confGroups) {
            this.internalResources = AllResources.filter(function (r) { return _this.dexecution.resources_in.includes(r.id); });
            this.externalResources = this.dexecution.resources.filter(function (r) { return !_this.dexecution.resources_in.includes(r); });
        }
        this.mustSelectFileIndex = DeviceModelService.isKarat300(this.dmodel);
    };
    AddDeviceSelectConfigurationComponent.prototype.defineSetup = function (withAlert) {
        var _this = this;
        if (withAlert === void 0) { withAlert = false; }
        this.wrongSetup = false;
        if (DeviceModelService.mustSetupSensor(DeviceModelService.getModel(this.device, this.dmodels))) {
            // для карат-926 необходимо настроить сенсор
            if (this.inputs.length === 0) {
                if (withAlert) {
                    WSLMaterializeHelper.toast({ html: 'Необходимо настроить вход!' });
                }
                this.wrongSetup = true;
            }
        }
        else if (!this.isCalc) {
            if (this.inputs.length < this.schema.resources.filter(function (res) { return _this.externalResources.includes(res.id); }).length) {
                if (withAlert) {
                    WSLMaterializeHelper.toast({ html: 'Необходимо настроить все входы ПУ!' });
                }
                this.wrongSetup = true;
            }
        }
        if (this.mustSelectFileIndex && !this.selectedFileIndex) {
            if (withAlert) {
                WSLMaterializeHelper.toast({ html: 'Необходимо выбрать файл конфигурации ПУ!' });
            }
            this.wrongSetup = true;
        }
    };
    return AddDeviceSelectConfigurationComponent;
}());
export { AddDeviceSelectConfigurationComponent };
