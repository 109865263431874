import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IWSLCommunicator} from 'wsl-device';
import {LoraNodeClasses} from '@app/communicator/enums/lora-node-class.enum';
import {ConnectionType} from '@app/communicator/enums/connection-type.enum';
import {LoRaWANProtocols} from '@app/communicator/enums/lorawan-protocol.enum';
import {CommunicatorService} from '@app/communicator/services/communicator.service';

@Component({
  selector: 'wsl-communicator-info',
  templateUrl: './communicator-info.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunicatorInfoComponent implements OnInit {
  @Input() communicator: IWSLCommunicator;

  isLoRa = false;
  isTCP = false;
  isGPRS = false;
  loraClass = null;
  loraProtocol = null;
  connection = null;

  constructor() { }

  ngOnInit() {
    this.define();
  }

  private define() {
    this.isLoRa = this.communicator.network.channel_id === ConnectionType.lora;
    this.isTCP = this.communicator.network.channel_id === ConnectionType.tcp || this.communicator.network.channel_id === ConnectionType.tcp_gprs;
    this.isGPRS = this.communicator.network.channel_id === ConnectionType.gprs || this.communicator.network.channel_id === ConnectionType.tcp_gprs;
    this.loraClass = this.isLoRa && this.communicator.network.lora.lora_class_id ?
      LoraNodeClasses.find(c => c.id === +this.communicator.network.lora.lora_class_id) : null;
    this.loraProtocol = this.isLoRa && this.communicator.network.lora.lora_version_id ?
      LoRaWANProtocols.find(c => c.id === +this.communicator.network.lora.lora_version_id) : null;

    if (this.communicator) {
      const nt = CommunicatorService.getConnectionType(this.communicator);
      if (!nt) {
        console.error(this.communicator);
      } else {
        this.connection = {
          icon: nt.icon,
          name: nt.name,
          // describe: 'LoRa'
        };
      }
    }
  }
}
