import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IWSLDeviceConfGroup } from '../../models/device-conf-group';
import { WSLActionButtonType } from 'wsl-shared';
import { Router } from '@angular/router';

@Component({
  selector: 'wsl-device-conf-group-list',
  templateUrl: './device-conf-group-list.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceConfGroupListComponent implements OnInit {
  @Input() deviceConfGroups: IWSLDeviceConfGroup[];
  @Input() checklist: number[] = [];
  @Input() checkable = false;
  @Input() readonly = false;
  @Output() check = new EventEmitter<{id: number, checked: boolean}>();
  @Output() selectItem = new EventEmitter<IWSLDeviceConfGroup>();
  @Output() delete = new EventEmitter<number>();

  actionButtons = [];

  constructor(private router: Router) { }

  ngOnInit() {
  }

  onBtnClick(ev) {
    switch (ev) {
      case WSLActionButtonType.add:
        this.router.navigate(['/support/add_device_conf_group']);
        break;
    }
  }

  trackById(index:  number, obj: IWSLDeviceConfGroup) {
    return obj.id;
  }

}
