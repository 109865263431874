import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IWSLDeviceInputV, IWSLDeviceSchemaV} from '@app/device/components/add-device-setup-metering-node/add-device-setup-metering-node.component';
import {IWSLDevice, IWSLDeviceExecution, IWSLDeviceModel, IWSLDeviceSensor} from 'wsl-device';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';
import {IWSLAsyncErrors, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {IWSLDeviceConf} from '@app/device-conf/models/device-conf';
import {IWSLDataConf} from '@app/device-conf-ext/models/data-conf';
import {IWSLDeviceConfGroupFilter} from '@app/device-conf-group/models/device-conf-group-filter';
import {IWSLDeviceConfFilter} from '@app/device-conf/models/device-conf-filter';
import {IWSLDataConfFilter} from '@app/device-conf-ext/models/data-conf-filter';
import {WSLMaterializeHelper} from 'wsl-shared';
import {IWSLDeviceConfGroupFileIndex, IWSLDeviceConfGroupFileIndexFilter} from '@app/device-conf-group/models/device-conf-group-file-index.model';
import {DeviceModelService} from '@app/device/services/device-model.service';
import {DeviceExecutionService} from '@app/device/services/device-execution.service';
import {AllResources, IWSLResource} from 'wsl-ek-core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'wsl-add-device-select-configuration',
  templateUrl: './add-device-select-configuration.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceSelectConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() device: IWSLDevice;
  @Input() schema: IWSLDeviceSchemaV;
  @Input() inputs: IWSLDeviceInputV[];
  @Input() deviceSensors: IWSLDeviceSensor[];
  @Input() confGroups: IWSLDeviceConfGroup[];
  @Input() confGroupPending: boolean;
  @Input() confGroupErrors: IWSLAsyncErrors;

  @Input() confs: IWSLDeviceConf[];
  @Input() confPending: boolean;
  @Input() confErrors: IWSLAsyncErrors;

  @Input() confData: IWSLDataConf[];
  @Input() confDataPending: boolean;
  @Input() confDataErrors: IWSLAsyncErrors;

  @Input() groupFileIndexes: IWSLDeviceConfGroupFileIndex[];
  @Input() groupFileIndexPending: boolean;
  @Input() groupFileIndexErrors: IWSLAsyncErrors;

  @Input() disabled = true;
  @Input() showBack = false;

 // @Output() loadConfGroups = new EventEmitter<IWSLDeviceConfGroupFilter>();
  @Output() loadConfs = new EventEmitter<IWSLDeviceConfFilter>();
  @Output() loadConfData = new EventEmitter<IWSLDataConfFilter>();
  @Output() loadFileIndex = new EventEmitter<IWSLDeviceConfGroupFileIndexFilter>();

  @Output() selectConfiguration = new EventEmitter<{group: IWSLDeviceConfGroup, fileIndex: IWSLDeviceConfGroupFileIndex}>();
  @Output() back = new EventEmitter();

  suitableGroups: IWSLDeviceConfGroup[] = [];
  selectedGroup: number = null;
  selectedFileIndex: number = null;
  wrongSetup = false;
  selectConfigurationClicked = false;
  dmodel: IWSLDeviceModel;
  isCalc = false;
  dexecution: IWSLDeviceExecution;
  internalResources: IWSLResource[] = [];
  externalResources: number[] = [];

  mustSelectFileIndex = false;

  private MModal;
  private dmodels: IWSLDeviceModel[] = [];
  private dexecutions: IWSLDeviceExecution[] = [];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.dexecutions = data.dexecutions;
    this.define();
    this.defineSuitableGroups();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device) {
      this.define();
    }
    if (changes.confGroups || changes.schema) {
      this.defineSuitableGroups();
    }
    if (changes.groupFileIndexes) {
      if (this.device && this.device.group_file_id) {
        this.selectedFileIndex = this.device.group_file_id;
      } else if (this.groupFileIndexes && this.groupFileIndexes.length === 1) {
        this.selectedFileIndex = this.groupFileIndexes[0].id;
      }
    }
    if (changes.inputs) {
      this.defineSetup();
    }
  }

  ngOnDestroy() {
    if (this.MModal) {
      this.MModal.close();
      this.MModal.destroy();
    }
  }

  private defineSuitableGroups() {
    this.selectedGroup = null;
    this.suitableGroups = !!this.confGroups && !!this.device ?
      this.confGroups
        .filter(group =>
             (this.device.communicators.filter(c => !!c.vr).length === 0 && (!group.protocols || group.protocols.length === 0)) ||
             (group.protocols && WSLUtils.intersection(group.protocols, this.device.communicators.map(c => c.vr)).length > 0)
        )
        .filter(group => {
          if (this.schema) {
            return group.ext.resources.length === this.schema.resources.length &&
              this.schema.resources
                .map(r => r.id)
                .reduce((prev, cur, index) => prev && group.ext.resources[index] && cur === group.ext.resources[index].resource_id, true);
          }
          if (this.device && this.device.group_id) {
            if (this.deviceSensors) {
              return group.id === this.device.group_id ||
                (
                  group.ext.resources.length === this.deviceSensors.length &&
                  WSLUtils.difference(
                    this.deviceSensors.map(s => s.resource_id),
                    group.ext.resources.map(r => r.resource_id)
                  ).length === 0
                );
            }
          }
          return false;
        }) : [];
    if (this.suitableGroups.length > 0) {
      if (this.suitableGroups.length === 1) {
        this.onSelectGroup({id: this.suitableGroups[0].id, checked: true});
      } else if (!!this.device.group_id) {
        if (!!this.suitableGroups.find(g => g.id === this.device.group_id)) {
          this.onSelectGroup({id: this.device.group_id, checked: true});
        }
      }
    }
  }

  onSelectGroup({id, checked}: {id: number, checked: boolean}) {
    if (checked && this.selectedGroup !== id) {
      this.selectedGroup = id;
      const group = this.suitableGroups.find(g => g.id === id);
      this.loadConfData.emit({dconf_ids: group.dconf_ids});
      if (!this.device.individual) {
        this.loadFileIndex.emit({group_id: this.selectedGroup});
      }
      this.defineSetup();
    } else if (!checked && this.selectedGroup === id) {
      this.selectedGroup = null;
    }
  }

  onSelectGroupFileIndex({id, checked}: {id: number, checked: boolean}) {
    if (checked && this.selectedFileIndex !== id) {
      this.selectedFileIndex = id;
    } else if (!checked && this.selectedFileIndex === id) {
      this.selectedFileIndex = null;
    }
  }

  onShowGroupConf(group: IWSLDeviceConfGroup) {
    this.MModal = WSLMaterializeHelper.initModal('#add_device_conf_data', {dismissible: true});
    this.openModal();
    this.loadConfs.emit({dconf_ids: group.dconf_ids});
    this.loadConfData.emit({dconf_ids: group.dconf_ids});
  }

  openModal() {
    this.MModal.open();
  }

  closeModal() {
    this.MModal.close();
  }

  trackById(index: number, obj: any): any {
    return obj.id;
  }

  onSelectConfiguration() {
    this.selectConfigurationClicked = true;
    this.defineSetup(true);
    if (this.wrongSetup) {
      return;
    }
    if (!!this.selectedGroup) {
      const group = this.suitableGroups.find(g => g.id === this.selectedGroup);
      const fileIndex = this.mustSelectFileIndex ? this.groupFileIndexes.find(f => f.id === this.selectedFileIndex) : null;
      this.selectConfiguration.emit({group, fileIndex});
    } else {
      WSLMaterializeHelper.toast({html: 'Выберите конфигурацию ПУ'});
    }
  }

  private define() {
    this.dmodel = DeviceModelService.getModel(this.device, this.dmodels);
    this.isCalc = DeviceModelService.isCalc(this.dmodel);
    this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
    if (this.dexecution && !!this.confGroups) {
      this.internalResources = AllResources.filter(r => this.dexecution.resources_in.includes(r.id));
      this.externalResources = this.dexecution.resources.filter(r => !this.dexecution.resources_in.includes(r));
    }
    this.mustSelectFileIndex = DeviceModelService.isKarat300(this.dmodel);
  }

  private defineSetup(withAlert = false) {
    this.wrongSetup = false;
    if (DeviceModelService.mustSetupSensor(DeviceModelService.getModel(this.device, this.dmodels))) {
      // для карат-926 необходимо настроить сенсор
      if (this.inputs.length === 0) {
        if (withAlert) {
          WSLMaterializeHelper.toast({html: 'Необходимо настроить вход!'});
        }
        this.wrongSetup = true;
      }
    } else if (!this.isCalc) {
      if (this.inputs.length < this.schema.resources.filter(res => this.externalResources.includes(res.id)).length) {
        if (withAlert) {
          WSLMaterializeHelper.toast({html: 'Необходимо настроить все входы ПУ!'});
        }
        this.wrongSetup = true;
      }
    }
    if (this.mustSelectFileIndex && !this.selectedFileIndex) {
      if (withAlert) {
        WSLMaterializeHelper.toast({html: 'Необходимо выбрать файл конфигурации ПУ!'});
      }
      this.wrongSetup = true;
    }
  }
}
