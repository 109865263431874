import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DeviceType, IWSLDevice, IWSLDeviceExecution, IWSLDeviceModel, IWSLDeviceSensor} from 'wsl-device';
import {IWSLAsyncErrors, MomentDBDateFormat, WSLRouterHelperService, WSLUtils} from 'wsl-core';
import {ActivatedRoute} from '@angular/router';
import {AllResources, IWSLResource, IWSLResourceUnit, IWSLResourceValue, IWSLResourceVariable, ResourceType, ResourceVariableType} from 'wsl-ek-core';
import {DeviceModelService} from '@app/device/services/device-model.service';
import {DeviceExecutionService} from '@app/device/services/device-execution.service';
import {IWSLDeviceConfGroup} from '@app/device-conf-group/models/device-conf-group';
import {WSLMaterializeHelper} from 'wsl-shared';
import * as moment from 'moment';
import {IWSLServiceOperationValuesExt} from '@app/service-operation/models/service-operation';

export interface IWSLDeviceInputV {
  id?: number;
  resource_id: number;
  resource?: IWSLResource;
  resource_inx?: number;
  rvalue_id?: number;
  rvalue?: IWSLResourceValue;
  rvariable_id?: number;
  rvariable?: IWSLResourceVariable;
  unit?: IWSLResourceUnit;
  entry?: number;
  sensor_id?: number;
  serial_num?: string;
  date_next?: string;
  impulse_weight?: string;
  device_id?: number;
  dconf_ext_id?: number;
  requiredValue?: boolean;
  value_beg?: string|number;
}

export interface IWSLDeviceSchemaGroupV {
  single?: IWSLDeviceSchemaV[];
  double?: IWSLDeviceSchemaV[];
  triple?: IWSLDeviceSchemaV[];
}

export interface IWSLDeviceSchemaV {
  id?: number;
  resources?: IWSLResource[];
}

const MAX_V_FOR_HEAT = 2;
const MAX_T_FOR_HEAT = 3; // one for external cold water
const MAX_P_FOR_HEAT = 2;

const MAX_V_FOR_HOT = 2;
const MAX_T_FOR_HOT = 2;
const MAX_P_FOR_HOT = 2;

const MAX_V_FOR_COLD = 1;
const MAX_T_FOR_COLD = 0;
const MAX_P_FOR_COLD = 1;

const SCHEMA_NO_RESOURCES = -1;
const SCHEMA_WITH_MAGNETS = -2;

@Component({
  selector: 'wsl-add-device-setup-metering-node',
  templateUrl: './add-device-setup-metering-node.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceSetupMeteringNodeComponent implements OnInit, OnChanges {
  @Input() device: IWSLDevice;
  @Input() sensors: IWSLDeviceSensor[];
  @Input() sensorPending: boolean;
  @Input() sensorErrors: IWSLAsyncErrors;
  @Input() confGroups: IWSLDeviceConfGroup[];
//  @Input() setupInputs: IWSLDeviceInputV[];
  @Input() setupSchemaId: number;
  @Input() disabledSensor = true;
  @Input() showNext = true;
  @Input() showValues = false;
  @Input() changeOnlyValues = false;
  @Input() values: IWSLServiceOperationValuesExt[];
  @Input() disabled: boolean;
  @Output() selectSchema = new EventEmitter<IWSLDeviceSchemaV>();
  @Output() setupMeteringNode = new EventEmitter<{inputs: IWSLDeviceInputV[], schema: IWSLDeviceSchemaV}>();

  private dmodels: IWSLDeviceModel[] = [];
  private dexecutions: IWSLDeviceExecution[] = [];
  private rvariables: IWSLResourceVariable[] = [];
  private rvalues: IWSLResourceValue[] = [];
  private runits: IWSLResourceUnit[] = [];

  dmodel: IWSLDeviceModel;
  isCalc = false;
  deviceType = null;
  canEditEntry = false;
  dexecution: IWSLDeviceExecution;
  internalResources: IWSLResource[] = [];
  externalResources: number[] = [];
  availableSchemas: IWSLDeviceSchemaGroupV = {single: [], double: [], triple: []};
  selectedSchema: IWSLDeviceSchemaV = null;
  availableSensors: IWSLDeviceSensor[] = [];

  inputs: IWSLDeviceInputV[] = [];
  inputClass = 'icon-cross wsl-gray';

  input: IWSLDeviceInputV = null;
  showInput = false;

  private inputsFilled = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const data = WSLRouterHelperService.collectRouteData(this.route);
    this.dmodels = data.dmodels;
    this.dexecutions = data.dexecutions;
    this.rvariables = data.resourceVariables;
    this.rvalues = data.resourceValues;
    this.runits = data.resourceUnits;
    this.define();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.device) {
      this.define();
    }
    if (changes.sensors) {
      if (this.showInput) {
        this.input = null;
        this.showInput = false;
      }
      this.setSensorsToInputs();
      this.defineAvailableSensors();
    }

    if (changes.confGroups && this.confGroups && this.confGroups.length > 0 &&
      [
        ...this.availableSchemas.single,
        ...this.availableSchemas.double,
        ...this.availableSchemas.triple
      ].length === 0) {
      this.defineSchemas();
    }
    if (changes.values) {
      this.fillValues();
    }
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  onShowInput(input: IWSLDeviceInputV) {
    this.input = {...input};
    this.defineAvailableSensors();
    this.showInput = true;
  }

  onSaveInput(input: IWSLDeviceInputV) {
    const inputIndex = this.inputs.findIndex(i => i.id === input.id);
    if (inputIndex > -1) {
      this.inputs[inputIndex] = {
        ...this.inputs[inputIndex],
        sensor_id: input.sensor_id,
        serial_num: input.serial_num,
        date_next: input.date_next,
        impulse_weight: input.impulse_weight,
        entry: input.entry,
        resource_id: input.resource_id,
        resource: AllResources.find(r => r.id === input.resource_id),
        resource_inx: input.resource_inx,
        rvalue_id: input.rvalue_id,
        rvalue: this.rvalues.find(r => r.id === input.rvalue_id),
        unit: this.runits.find(r => r.rvariable_id === input.rvariable_id && r.multiplier === '1'),
        value_beg: input.value_beg
      };
      this.inputs = this.inputs.slice(0);
    }
    this.onHideInput();
    if (!this.showNext) {
      this.onSetupMeteringNode();
    }
  }

  onDeleteInput(input: IWSLDeviceInputV) {
    const inputIndex = this.inputs.findIndex(i => i.id === input.id);
    if (inputIndex > -1) {
      this.inputs[inputIndex] = {
        ...this.inputs[inputIndex],
        rvalue_id: null,
        rvalue: null,
        unit: null,
        sensor_id: null,
        serial_num: null,
        date_next: null,
        impulse_weight: null,
        value_beg: null,
      };
      this.inputs = this.inputs.slice(0);
      this.input = this.inputs[inputIndex];
    }
    this.defineAvailableSensors();
    if (!this.showNext) {
      this.onSetupMeteringNode();
    }
  }

  onHideInput() {
    this.input = null;
    this.showInput = false;
  }

  onSetupMeteringNode() {
    if (!this.selectedSchema) {
      return;
    }
    let filledInputs = this.inputs.filter(input => !!input.serial_num);

    if (DeviceModelService.mustSetupSensor(DeviceModelService.getModel(this.device, this.dmodels))) {
      // для карат-926 необходимо настроить сенсор
      if (filledInputs.length === 0) {
        if (!this.disabled) {
          WSLMaterializeHelper.toast({html: 'Необходимо настроить вход!'});
        }
        return;
      }
    } else if (!this.isCalc) {
      if (this.selectedSchema.resources.length === 0) {
        filledInputs = [];
      } else if (filledInputs.length !== this.selectedSchema.resources.filter(res => this.externalResources.includes(res.id)).length) {
        //  WSLMaterializeHelper.toast({html: 'Необходимо настроить все входы ПУ!'});
        return;
      }
    }
    if (filledInputs.some(i => !i.entry || !i.date_next || !i.resource_inx || !i.rvalue_id)) {
      if (!this.disabled) {
        WSLMaterializeHelper.toast({html: 'Заполните данные датчиков'});
      }
      return;
    }
    if (filledInputs.some(i => !!filledInputs.find(j => j.id !== i.id && i.entry === j.entry))) {
      if (!this.disabled) {
        WSLMaterializeHelper.toast({html: 'Датчики должны быть подключены на разные входы'});
      }
      return;
    }
    if (filledInputs.some(i => !!filledInputs.find(j => j.id !== i.id && i.rvalue_id === j.rvalue_id))) {
      if (!this.disabled) {
        WSLMaterializeHelper.toast({html: 'Датчики должны быть настроены к разным параметрам'});
      }
      return;
    }
    this.setupMeteringNode.emit({inputs: filledInputs, schema: this.selectedSchema});
  }


  onSelectSchema(schema: IWSLDeviceSchemaV) {
    if (this.disabledSensor || (this.selectedSchema && this.selectedSchema.id === schema.id)) {
      return;
    }
    this.selectedSchema = {...schema};
    if (this.selectedSchema.resources.length === 1 && this.selectedSchema.resources[0].id === SCHEMA_NO_RESOURCES) {
      this.selectedSchema.resources = [];
    }
    this.selectSchema.emit(this.selectedSchema);
    this.onHideInput();
    this.defineInputs();
  }

  private define() {
    this.dmodel = DeviceModelService.getModel(this.device, this.dmodels);
    this.isCalc = DeviceModelService.isCalc(this.dmodel);
    this.deviceType = DeviceModelService.getDeviceType(this.dmodel);
    this.canEditEntry = this.isCalc;
    this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
    if (this.dexecution && !!this.confGroups) {
      this.internalResources = AllResources.filter(r => this.dexecution.resources_in.includes(r.id));
      this.externalResources = this.dexecution.resources.filter(r => !this.dexecution.resources_in.includes(r));
    }
    if (this.inputs.length === 0 && !!this.selectedSchema) {
      this.defineInputs();
    }
  }

  private defineSchemas() {
    if (!this.confGroups || this.confGroups.length === 0 || !this.device) {
      return;
    }
    let schemaId = 1;
    this.availableSchemas = {single: [], double: [], triple: []};
    this.confGroups
      .filter(group => group.id === this.device.group_id ||
        (this.device.communicators.filter(c => !!c.vr).length === 0 && (!group.protocols || group.protocols.length === 0)) ||
        (group.protocols && WSLUtils.intersection(group.protocols, this.device.communicators.map(c => c.vr)).length > 0)
      )
      .forEach(group => {
        if (group.ext && group.ext.resources) {
          if (group.ext.resources.length === 1) {
            if (!this.availableSchemas.single.some(schema => schema.resources[0].id === group.ext.resources[0].resource_id)) {
              this.availableSchemas.single.push({
                id: schemaId,
                resources: group.ext.resources
                  .map(res => AllResources.find(r => r.id === res.resource_id))
              });
              schemaId++;
            }
          } else if (group.ext.resources.length === 0) {
            if (!this.availableSchemas.single.some(schema => schema.resources[0].id === SCHEMA_NO_RESOURCES)) {
              this.availableSchemas.single.push({
                id: schemaId,
                resources: [{id: SCHEMA_NO_RESOURCES, name: '', icon: 'icon-cross wsl-black'}]
              });
              schemaId++;
            }
          } else if (group.ext.resources.length === 2) {
            if (!this.schemaExist(this.availableSchemas.double, group)) {
              this.availableSchemas.double.push({
                id: schemaId,
                resources: group.ext.resources.map(res => AllResources.find(r => r.id === res.resource_id))
              });
              schemaId++;
            }
          } else if (group.ext.resources.length > 2) {
            if (!this.schemaExist(this.availableSchemas.triple, group)) {
              this.availableSchemas.triple.push({
                id: schemaId,
                resources: group.ext.resources.map(res => AllResources.find(r => r.id === res.resource_id))
              });
              schemaId++;
            }
          }
        }
      });
    // @todo magnet control
    /* if (this.confGroups.some(g => g.ext && g.ext.magnet_control)) {
       this.availableSchemas.single.push({
         id: schemaId,
         resources: [{id: SCHEMA_WITH_MAGNETS, name: '', icon: 'icon-magnet wsl-black'}]
       });
       schemaId++;
     }*/
    if (schemaId === 2) {
      // только одна схема доступна для выбора - выберем ее сразу!
      if (this.availableSchemas.single.length > 0) {
        this.onSelectSchema(this.availableSchemas.single[0]);
      } else if (this.availableSchemas.double.length > 0) {
        this.onSelectSchema(this.availableSchemas.double[0]);
      } else if (this.availableSchemas.triple.length > 0) {
        this.onSelectSchema(this.availableSchemas.triple[0]);
      }
    } else if (!!this.setupSchemaId) {
      // вернулись назад, выберем схему, которые выбрали раньше
      const schema = [...this.availableSchemas.single, ...this.availableSchemas.double, ...this.availableSchemas.triple]
        .find(s => s.id === this.setupSchemaId);
      if (schema) {
        this.onSelectSchema(schema);
      }
    } else if (!!this.device && this.device.group_id) {
      // в приборе выбрана конфигурация - найдем подходящую схему
      const group = this.confGroups.find(g => g.id === this.device.group_id);
      if (group) {
        const schema = [...this.availableSchemas.single, ...this.availableSchemas.double, ...this.availableSchemas.triple]
          .filter(s => s.resources.length >= group.ext.resources.length)
          .find(s => s.resources
            .map(r => r.id)
            .reduce((prev, cur, index) => prev && group.ext.resources[index] && cur === group.ext.resources[index].resource_id, true)
          );
        if (schema) {
          this.onSelectSchema(schema);
        } else if (group.ext.resources.length === 0 && this.availableSchemas.single.some(s => s.resources[0].id === SCHEMA_NO_RESOURCES)) {
          this.onSelectSchema(this.availableSchemas.single.find(s => s.resources[0].id === SCHEMA_NO_RESOURCES));
        }
      }
    }
  }

  private schemaExist(schemas: IWSLDeviceSchemaV[], group: IWSLDeviceConfGroup) {
    return schemas.some(schema => schema.resources
      .map(r => r.id)
      .reduce((prev, cur, index) => prev && group.ext.resources[index] && cur === group.ext.resources[index].resource_id, true)
    );
  }

  private defineInputs() {
    this.inputs = [];
    if (!this.selectedSchema || !this.dexecution) {
      return;
    }
    // если схема с 4 хвс - то выбор только из хвс!
    const resources = Array.from(new Set(this.selectedSchema.resources.map(r => r.id)));
    this.selectedSchema.resources.forEach(resource => {
      switch (resource.id) {
        case ResourceType.heat:
          if (this.isCalc) {
            this.defineHeatInputs(resource);
          } else {
            /*
              @todo вроде бы к 927 можно что-то подключить, но его пока нет в производстве
              для остальных вообще ничего
             */
          }
          break;
        case ResourceType.hot:
          if (this.isCalc) {
            this.defineHotInputs(resource);
          } else {
            /*
              для 926, 526, 213, 223 - можно подключить только вертушки V
              для 927 - можно подключить как V так и любой датчик (как у 306/7/8)
             */
            if (this.deviceType.id === DeviceType.node) {
              this.defineHotInputs(resource);
            } else {
              this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
            }
          }
          break;
        case ResourceType.cold:
          if (this.isCalc) {
            this.defineColdInputs(resource);
          } else {
            /*
              для 926, 526, 213, 223 - можно подключить только вертушки V
              для 927 - можно подключить как V так и любой датчик (как у 306/7/8)
             */
            if (this.deviceType.id === DeviceType.node) {
              this.defineHotInputs(resource);
            } else {
              this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
            }
          }
          break;
        default:
          // газ и электричество как импульс можно, но хз
          break;
      }
    });
    this.setSensorsToInputs();
    this.defineAvailableSensors();
    /*if (this.setupInputs && this.setupInputs.length > 0) {
      this.fillInputs();
    }*/
    if (this.values) {
      this.fillValues();
    }
    if (this.changeOnlyValues) {
      this.inputs = this.inputs.filter(i => !!i.sensor_id);
    }
    this.inputs.sort((a, b) => a.entry < b.entry ? -1 : 1);

    if (this.inputs.length === 1) {
      this.onShowInput(this.inputs[0]);
    }
    this.onSetupMeteringNode();
  }

  private defineAvailableSensors() {
    this.availableSensors = this.sensors ? this.sensors.filter(s => !this.inputs.find(inp => inp.sensor_id === s.id)) : [];
  }

  private defineColdInputs(resource) {
    for (let i = 0; i < Math.min(MAX_V_FOR_COLD, this.dexecution.v); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
    }
    for (let i = 0; i < Math.min(MAX_P_FOR_COLD, this.dexecution.p); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.p));
    }
    for (let i = 0; i < Math.min(MAX_T_FOR_COLD, this.dexecution.t); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.t));
    }
  }

  private defineHeatInputs(resource) {
    for (let i = 0; i < Math.min(MAX_V_FOR_HEAT, this.dexecution.v); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
    }
    for (let i = 0; i < Math.min(MAX_P_FOR_HEAT, this.dexecution.p); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.p));
    }
    for (let i = 0; i < Math.min(MAX_T_FOR_HEAT, this.dexecution.t); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.t));
    }
  }

  private defineHotInputs(resource) {
    for (let i = 0; i < Math.min(MAX_V_FOR_HOT, this.dexecution.v); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.v));
    }
    for (let i = 0; i < Math.min(MAX_P_FOR_HOT, this.dexecution.p); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.p));
    }
    for (let i = 0; i < Math.min(MAX_T_FOR_HOT, this.dexecution.t); i++) {
      this.inputs.push(this.defineInputForResource(resource.id, ResourceVariableType.t));
    }
  }

  private defineInputForResource(resource_id: number, rvariable_id: number) {
    return {
      id: this.inputs.length + 1,
      rvariable_id,
      rvariable: this.rvariables.find(r => r.id === rvariable_id),
      rvalue_id: null,
      rvalue: null,
      unit: null,
      resource_inx: 1,
      resource_id,
      resource: AllResources.find(r => r.id === resource_id),
      entry: this.inputs.length + 1,
      sensor_id: null,
      serial_num: null,
      date_next: null,
      impulse_weight: null,
      device_id: this.device.id,
      dconf_ext_id: null,
      requiredValue: this.showValues && !this.isCalc,
      value_beg: null
    };
  }

  errorInput(input: IWSLDeviceInputV) {
    return !this.disabled && !!input.serial_num && (
      !input.entry || !input.date_next || moment(input.date_next, MomentDBDateFormat).isSameOrBefore(moment(), 'day') ||
      !input.resource_inx || !input.rvalue_id || (input.requiredValue && input.value_beg === null) ||
      !!this.inputs.find(j => j.id !== input.id && !!j.serial_num && (j.rvalue_id === input.rvalue_id || input.entry === j.entry))
    );
  }

  successInput(input: IWSLDeviceInputV) {
    return !!input.serial_num && !this.errorInput(input);
  }

  /*private fillInputs() {
    if (!this.selectedSchema) {
      return;
    }
    this.setupInputs
      .forEach(si => {
        let id =  this.inputs.findIndex(i => this.isEqualInputs(i, si));
        if (id < 0) {
          id = this.inputs.findIndex(i => this.isSimilarFreeInput(i, si));
        }
        if (id > -1) {
          this.inputs[id] = {
            ...this.inputs[id],
            rvalue_id: si.rvalue_id,
            entry: si.entry,
            resource_inx: si.resource_inx,
            sensor_id: si.sensor_id,
            serial_num: si.serial_num,
            date_next: si.date_next,
            impulse_weight: si.impulse_weight,
            value_beg: si.value_beg,
            rvalue: this.rvalues.find(r => r.id === si.rvalue_id),
            unit: this.runits.find(r => r.rvariable_id === si.rvariable_id && r.multiplier === '1')
          };
        }
      });
    this.inputsFilled = true;
  }*/

  private setSensorsToInputs() {
    if (!this.sensors || this.sensors.length === 0) {
      return;
    }
    this.sensors.forEach(sensor => {
      let id = -1;
      const rvalue = this.rvalues.find(rv => rv.id === sensor.rvalue_id);
      if (!!rvalue && sensor.rvalue_id) {
        id = this.inputs.findIndex(i => this.isEqualInputs(i, {...sensor, sensor_id: sensor.id, rvariable_id: rvalue.rvariable_id}));
        if (id < 0) {
          id = this.inputs.findIndex(i => this.isSimilarFreeInput(i, {...sensor, sensor_id: sensor.id, rvariable_id: rvalue.rvariable_id}));
        }
      } else if (this.inputs.length === 1) {
        // for karat 926 from EPassport
        id = 0;
      }
      if (id > -1) {
        //     const availableRvalues = this.rvalues.filter(r => r.resource_id === sensor.resource_id);
        this.inputs[id] = {
          ...this.inputs[id],
          sensor_id: sensor.id,
          serial_num: sensor.serial_num,
          rvalue_id: sensor.rvalue_id,
          entry: sensor.entry,
          resource_inx: sensor.resource_inx,
          date_next: sensor.date_next,
          impulse_weight: sensor.impulse_weight,
          value_beg: null, // +sensor.value || +sensor.value_beg
          rvalue,
          unit: rvalue ? this.runits.find(r => r.rvariable_id === rvalue.rvariable_id && r.multiplier === '1') : null
        };
      }
    });
    this.inputs = this.inputs.filter(i => !i.sensor_id || (i.sensor_id && this.sensors && !!this.sensors.find(s => s.id === i.sensor_id)));
  }

  private fillValues() {
    if (!this.values || this.values.length === 0 || this.inputs.length === 0) {
      return;
    }
    this.values.forEach(v => {
      let id =  this.inputs.findIndex(i => this.isEqualInputs(i, v));
      if (id < 0) {
        id = this.inputs.findIndex(i => this.isSimilarFreeInput(i, v));
      }
      if (id < 0 && this.values.length === 1 && this.inputs.length === 1) {
        id = 0;
        if (!v.serial_num && v.sensor_id) {
          const sensor = this.sensors && this.sensors.find(s => s.id === v.sensor_id);
          if (sensor) {
            v = {...v, serial_num: sensor.serial_num};
          }
        }
      }
      if (id > -1) {
        this.inputs[id] = {
          ...this.inputs[id],
          ...v,
          rvalue: this.rvalues.find(r => r.id === v.rvalue_id),
          unit: this.runits.find(r => r.rvariable_id === v.rvariable_id && r.multiplier === '1')
        };
      }
    });
  }

  private isEqualInputs(a: IWSLDeviceInputV, b: IWSLDeviceInputV|IWSLServiceOperationValuesExt) {
    return a.sensor_id === b.sensor_id ||
      (
        !a.sensor_id && (
          (a.serial_num === b.serial_num  && a.resource_inx === b.resource_inx && a.rvalue_id === b.rvalue_id)
        )
      );
  }

  private isSimilarFreeInput(a: IWSLDeviceInputV, b: IWSLDeviceInputV|IWSLServiceOperationValuesExt) {
    return !a.sensor_id && !a.serial_num && a.rvariable_id === b.rvariable_id && a.resource_id === b.resource_id;
  }
}
