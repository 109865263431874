import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit} from '@angular/core';
import {DeviceService, IWSLDevice, IWSLDeviceSensor} from 'wsl-device';
import {catchError, finalize, take, tap} from 'rxjs/operators';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {of} from 'rxjs';
import {WSLFormHelper, WSLHttpHelper, WSLUtils} from 'wsl-core';
import {WSLMaterializeHelper} from 'wsl-shared';
import {DeviceDocumentService} from '@app/device/services/device-document.service';
import {IDeviceDocument} from '@app/device/models/device-document.model';
import {DeviceDocumentType} from '@app/device/enums/device-document-type.enum';
import {IWSLUser} from 'wsl-user';


@Component({
  selector: 'wsl-device-files',
  templateUrl: './device-files.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceFilesComponent implements OnInit {
  @Input() device: IWSLDevice;
  @Input() sensors: IWSLDeviceSensor[];
  @Input() users: IWSLUser[];

  token: string;

  loading = false;
  error = null;
  documents: IDeviceDocument[] = [];

  showAddDocumentsForm = false;



  form = new FormGroup({
    documents: new FormArray([])
  });

  constructor(private deviceService: DeviceService,
              private documentService: DeviceDocumentService,
              private elementRef: ElementRef,
              private chr: ChangeDetectorRef) { }

  ngOnInit() {
    this.loadDocuments();
  }

  onShowAddDocumentForOperator() {
    this.error = null;
    this.showAddDocumentsForm = true;
    WSLFormHelper.clearFormArray(<FormArray>this.form.get('documents'));
    this.addDocument();
    // (<FormArray>this.form.get('documents')).c
  }

  get docControls(): FormGroup[] {
    return (<FormArray>this.form.get('documents')).controls as FormGroup[];
  }



  onUploadDocuments() {
    const partFilledDocumnets = this.form.value.documents.filter(d => !d.type_object_id || !!d.type || !!d.fileURL);
    const fullFilledDocuments = this.form.value.documents.filter(d => !!d.type_object_id && !!d.type && !!d.fileURL);

    if (fullFilledDocuments.length === 0) {
      if (partFilledDocumnets.some(d => !d.type)) {
        WSLMaterializeHelper.toast({html: 'Задайте тип документа!'});
        return;
      }
      if (partFilledDocumnets.some(d => !d.type_object_id)) {
        WSLMaterializeHelper.toast({html: 'Задайте прибор!'});
        return;
      }
      if (partFilledDocumnets.some(d => !d.fileURL)) {
        WSLMaterializeHelper.toast({html: 'Задайте файл!'});
        return;
      }
    }

    const formData = new FormData();
    // formData.append('device_id', JSON.stringify(this.device.id));

    for (let i = 0; i < fullFilledDocuments.length; i++) {
      formData.append('file[]', fullFilledDocuments[i].file);
      formData.append('type[]', fullFilledDocuments[i].type);
      //    formData.append('name[]', fullFilledDocuments[i].name);
      formData.append('type_object_id[]', fullFilledDocuments[i].type_object_id);
    }

    this.loading = true;
    this.error = null;
    this.documentService.add(<any>formData)
      .pipe(
        take(1),
        tap(res => {
          if (!res.error) {
            this.showAddDocumentsForm = false;
            this.loadDocuments();
          }
        }),
        catchError(e => {
          const error = WSLHttpHelper.parseHTTPErrorResponse(e);
          this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
          return of(e);
        }),
        finalize(() => {
          this.loading = false;
          this.chr.markForCheck();
        })
      ).subscribe();

  }

  onCancelUploadDocuments() {
    WSLFormHelper.clearFormArray(<FormArray>this.form.get('documents'));
    this.showAddDocumentsForm = false;
    this.error = null;
  }

  addDocument() {
    const fA = <FormArray>this.form.get('documents');
    if (fA.length === 0 || fA.value.every(fg => !!fg.type_object_id && !!fg.type && !!fg.fileURL)) {
      fA.push(new FormGroup({
        type: new FormControl(null),
        type_object_id: new FormControl(null),
        //    name: new FormControl(''),
        file: new FormControl(null),
        fileURL: new FormControl(null),
        fileName: new FormControl(null),
        fileSize: new FormControl(null)
      }));
    }
  }

  trackById(index: number, file: IDeviceDocument) {
    return file.id;
  }

  private loadDocuments() {
    this.loading = true;
    this.error = null;
    this.documentService.getMany({type_object_id: this.device.id, type: DeviceDocumentType.passportDevice})
      .pipe(
        take(1),
        tap(res => {
          if (!res.error) {
            this.documents = res.items;
          } else {
          }
        }),
        catchError(e => {
          this.documents = [];
          const error = WSLHttpHelper.parseHTTPErrorResponse(e);
          this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
          return of(e);
        }),
        finalize(() => {
          this.loading = false;
          this.chr.markForCheck();
        })
      ).subscribe();
  }

  onDownloadFile(id: number) {
    this.loading = true;
    this.error = null;
    this.documentService.get(id)
      .pipe(
        take(1),
        tap(res => {
          const uid = 'doc' + WSLUtils.randomID();
          this.elementRef.nativeElement.insertAdjacentHTML('beforeend',
            `<a id="${uid}" class="wsl-hide" href="${(<any>res).url}" target="_blank"></a>`);
          setTimeout(() => {
            const el = this.elementRef.nativeElement.querySelector('#' + uid);
            el.click();
            this.elementRef.nativeElement.removeChild(el);
          }, 2);
        }),
        catchError(e => {
          const error = WSLHttpHelper.parseHTTPErrorResponse(e);
          this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
          return of(e);
        }),
        finalize(() => {
          this.loading = false;
          this.chr.markForCheck();
        })
        )
      .subscribe();
  }

  onDeleteFile(id: number) {
    this.loading = true;
    this.error = null;
    this.documentService.delete(id)
      .pipe(
        take(1),
        tap(res => {
          this.loadDocuments();
        }),
        catchError(e => {
          const error = WSLHttpHelper.parseHTTPErrorResponse(e);
          this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
          return of(e);
        }),
        finalize(() => {
          this.loading = false;
          this.chr.markForCheck();
        })
      )
      .subscribe();
  }
}
