import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WSLRouterHelperService } from 'wsl-core';
import { DeviceExecutionService } from '@app/device/services/device-execution.service';
import { DeviceModelService } from '@app/device/services/device-model.service';
import { AllResources } from 'wsl-ek-core';
import { KaratLoraProtocolService } from '@core/services/karat-lora-protocol.service';
import { WSLMaterializeHelper } from 'wsl-shared';
var DeviceInfoComponent = /** @class */ (function () {
    function DeviceInfoComponent(route) {
        this.route = route;
        this.renameDevice = new EventEmitter();
        this.changeModbus = new EventEmitter();
        this.dmodels = [];
        this.dexecutions = [];
        this.confGroupResources = [];
        this.confGroupProtocols = [];
        this.alias = '';
        this.modbus = '';
        this.showConfData = false;
    }
    DeviceInfoComponent.prototype.ngOnInit = function () {
        var data = WSLRouterHelperService.collectRouteData(this.route);
        this.dmodels = data.dmodels;
        this.dexecutions = data.dexecutions;
    };
    DeviceInfoComponent.prototype.ngOnChanges = function (changes) {
        if (changes.device) {
            this.define();
        }
        if (changes.objects) {
            this.defineObject();
        }
        if (changes.rooms) {
            this.defineRoom();
        }
        if (changes.confGroups) {
            this.defineConfGroup();
        }
        if (changes.groupFileIndexes) {
            this.defineConfGroupFileIndex();
        }
        if (changes.sensors) {
            if (this.sensors) {
                this.sensors.sort(function (a, b) { return a.entry < b.entry ? -1 : 1; });
            }
        }
    };
    DeviceInfoComponent.prototype.define = function () {
        if (this.device) {
            this.dmodel = DeviceModelService.getModel(this.device, this.dmodels);
            this.dexecution = DeviceExecutionService.getExecution(this.device, this.dexecutions);
            this.alias = this.device.alias;
            this.modbus = this.device.modbus_addr;
            this.defineObject();
            this.defineRoom();
        }
    };
    DeviceInfoComponent.prototype.defineObject = function () {
        var _this = this;
        this.object = this.objects && this.device ? this.objects.find(function (o) { return o.id === _this.device.object_id; }) : null;
    };
    DeviceInfoComponent.prototype.defineRoom = function () {
        var _this = this;
        this.room = this.rooms && this.device ? this.rooms.find(function (r) { return r.id === _this.device.room_id; }) : null;
    };
    DeviceInfoComponent.prototype.defineConfGroup = function () {
        var _this = this;
        this.confGroup = null;
        if (this.device && this.device.group_id && this.confGroups && this.confGroups.length > 0) {
            this.confGroup = this.confGroups.find(function (g) { return g.id === _this.device.group_id; });
            if (this.confGroup) {
                this.confGroupResources = this.confGroup.ext && this.confGroup.ext.resources ?
                    AllResources.filter(function (ar) { return _this.confGroup.ext.resources.map(function (r) { return r.resource_id; }).includes(ar.id); }) :
                    [];
                this.confGroupProtocols = this.confGroup.protocols ? KaratLoraProtocolService.get().filter(function (p) { return _this.confGroup.protocols.includes(p.id); }) : [];
            }
        }
    };
    DeviceInfoComponent.prototype.defineConfGroupFileIndex = function () {
        var _this = this;
        if (this.device && this.device.group_file_id && this.groupFileIndexes && this.groupFileIndexes.length > 0) {
            this.groupFileIndex = this.groupFileIndexes.find(function (f) { return f.id === _this.device.group_file_id; });
        }
    };
    DeviceInfoComponent.prototype.onRenameDevice = function () {
        if (this.alias !== this.device.alias && !this.device.individual) {
            this.renameDevice.emit(tslib_1.__assign({}, this.device, { alias: this.alias }));
        }
    };
    DeviceInfoComponent.prototype.onShowConfData = function () {
        this.MModal = WSLMaterializeHelper.initModal('#device_conf_data', { dismissible: true });
        this.openModal();
        // this.loadConfs.emit({dconf_ids: group.dconf_ids});
    };
    DeviceInfoComponent.prototype.openModal = function () {
        this.MModal.open();
    };
    DeviceInfoComponent.prototype.closeModal = function () {
        this.MModal.close();
    };
    DeviceInfoComponent.prototype.trackById = function (index, obj) {
        return obj.id;
    };
    DeviceInfoComponent.prototype.onChangeDeviceModbus = function () {
        if (this.modbus !== this.device.modbus_addr) {
            this.changeModbus.emit(this.modbus);
        }
    };
    return DeviceInfoComponent;
}());
export { DeviceInfoComponent };
