<div class="wsl-flex-column-ng wsl-h-100 wsl-select-inline">
    <!--<label style="line-height: 1.8;">{{label}}</label>-->

    <div class="wsl-flex-middle-spaceBetween-ng wsl-col-12 wsl-w-100">
        <label>{{label}}<span class="wsl-red" *ngIf="required" matTooltip="поле обязательное">*</span></label>

        <div class="wsl-small" *ngIf="!isRadio" [ngClass]="{'wsl-color-primary wsl-hover': !disabled, 'wsl-gray': disabled}" (click)="selectAll()">
            <span class="wsl-m-r-5" [ngClass]="{'icon-check-square': value.length === 0, 'icon-square': value.length > 0}"></span>
            <span>все</span>
        </div>
        <div class="wsl-small" *ngIf="!isRadio && value.length > 0">
            <span matTooltip="выбрано" class="wsl-badge wsl-bold wsl-bg-red wsl-white wsl-m-t-3">{{value.length}}</span>
        </div>
    </div>

    <div class="wsl-m-b-5 wsl-relative" [class.wsl-hide]="!showSearch">
        <input class="form-control"
               type="text"
               placeholder="Введите название"
               [disabled]="disabled"
               [(ngModel)]="search" (ngModelChange)="onWriteSearch($event)">
        <div class="input-icon" [class.wsl-hide]="search==='' || disabled" (click)="onClearSearch()">
            <a class="icon-cross wsl-st5 wsl-red wsl-hover"></a>
        </div>
    </div>
    <div class="wsl-bg-white wsl-border wsl-text-nowrap wsl-relative" style="overflow-y: scroll; overflow-x: hidden; flex-grow: 10;" [attr.id]="prefix+'_select_inline_container'" >
        <div class="wsl-m-y-0" #container wslSpyScroll (scrolled)="onScroll($event)">
            <div class="bg wsl-border-b" *ngIf="!isRadio">
                <div *ngFor="let option of selectedItems; trackBy: trackById"
                     [attr.id]="prefix+'_selected_inline_item_'+option.id"
                     #row class="">
                    <div class="wsl-flex-middle-nowrap-ng">
                        <div class="wsl-col">
                            <label [attr.for]="prefix+'_selected_checkbox_item_'+option.id"
                                   class="wsl-p-x-10 wsl-p-y-5 wsl-w-100 wsl-hover wsl-relative"
                                   [ngClass]="{'': value.indexOf(option.id) >= 0,
                                        'wsl-bg-white_hover':value.indexOf(option.id) < 0 && !disabled,
                                        'wsl-bg-gray-lightest': value.indexOf(option.id) < 0 && disabled}">
                                <input type="checkbox" class="filled-in" [attr.id]="prefix+'_selected_checkbox_item_'+option.id"
                                       [disabled]="disabled"
                                       [ngModel]="value.indexOf(option.id) >= 0"
                                       (ngModelChange)="onToggle($event, option.id)"/>
                                <span [innerHTML]="option.name"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let option of sortItems; trackBy: trackById"
                 [attr.id]="prefix+'_select_inline_item_'+option.id"
                #row class="">

                <ng-container *ngIf="isRadio; else isCheckbox">
                    <div class="wsl-flex-middle-nowrap-ng">
                        <div class="wsl-col">
                            <label [attr.for]="prefix+'_select_radio_item_'+option.id"
                                   class="wsl-p-x-10 wsl-p-y-5 wsl-w-100 wsl-hover wsl-relative"
                                   [ngClass]="{'wsl-bg-gray wsl-white': option.id === value,
                                    'wsl-bg-white_hover': option.id !== value && !disabled,
                                    'wsl-bg-gray-light': option.id === value && disabled,
                                    'wsl-bg-gray-lightest': disabled && option.id !== value}">
                                <input class="" type="radio" [(ngModel)]="value" [value]="option.id" [disabled]="disabled"
                                       [attr.id]="prefix+'_select_radio_item_'+option.id">
                                <span [innerHTML]="option.name"></span>
                                <style>
                                    .wsl-select-inline [type="radio"]:not(:checked)+span, [type="radio"]:checked+span {
                                        padding-left: 0px;
                                    }
                                    .wsl-select-inline [type="radio"]+span:before, [type="radio"]+span:after {
                                        display: none;
                                    }
                                </style>
                            </label>
                        </div>
                    </div>
                </ng-container>
                <ng-template #isCheckbox>
                    <div class="wsl-flex-middle-nowrap-ng">
                        <div class="wsl-col">
                            <label [attr.for]="prefix+'_select_checkbox_item_'+option.id"
                                   class="wsl-p-x-10 wsl-p-y-5 wsl-w-100 wsl-hover wsl-relative"
                                   [class.checked-many]="subItems[option.id] && subItems[option.id].length > 0 && subItems[option.id].length < option.items.length"
                                   [ngClass]="{'': value.indexOf(option.id) >= 0,
                                    'wsl-bg-white_hover':value.indexOf(option.id) < 0 && !disabled,
                                    'wsl-bg-gray-lightest': value.indexOf(option.id) < 0 && disabled}">
                                <input type="checkbox" class="filled-in"
                                       [attr.id]="prefix+'_select_checkbox_item_'+option.id"
                                       [disabled]="disabled"
                                       [ngModel]="value.indexOf(option.id) >= 0"
                                       (ngModelChange)="onToggle($event, option.id)"/>
                                <span [innerHTML]="option.name"></span>
                            </label>
                        </div>
                        <a class="wsl-st5 wsl-p-x-5"
                           *ngIf="option.items && option.items.length > 0 && !disabled"
                           [ngClass]="{'icon-plus-circle': !showSubItems.includes(option.id), 'icon-circle-minus': showSubItems.includes(option.id)}"
                           (click)="onToggleSubItems(option.id)"></a>
                    </div>
                    <div class="wsl-p-l-20" *ngIf="showSubItems.includes(option.id)">
                        <div *ngFor="let item of option.items; trackBy: trackById">

                            <label [attr.for]="prefix+'_select_checkbox_sub_item_'+item.id"
                                   class="wsl-p-x-10 wsl-p-y-5 wsl-w-100 wsl-hover">
                                <input type="checkbox" class="filled-in" [attr.id]="prefix+'_select_checkbox_sub_item_'+item.id"
                                       [disabled]="disabled"
                                       [ngModel]="value.indexOf(item.id) >= 0" (ngModelChange)="onToggleSubItem($event, option.id, item.id)">
                                <span [innerHTML]="item.name"></span>
                            </label>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div *ngIf="pending">
                <mat-progress-bar mode="buffer"></mat-progress-bar>
            </div>
        </div>
    </div>
</div>
