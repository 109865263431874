import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { DeviceService } from 'wsl-device';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { WSLFormHelper, WSLHttpHelper, WSLUtils } from 'wsl-core';
import { WSLMaterializeHelper } from 'wsl-shared';
import { DeviceDocumentService } from '@app/device/services/device-document.service';
import { DeviceDocumentType } from '@app/device/enums/device-document-type.enum';
var DeviceFilesComponent = /** @class */ (function () {
    function DeviceFilesComponent(deviceService, documentService, elementRef, chr) {
        this.deviceService = deviceService;
        this.documentService = documentService;
        this.elementRef = elementRef;
        this.chr = chr;
        this.loading = false;
        this.error = null;
        this.documents = [];
        this.showAddDocumentsForm = false;
        this.form = new FormGroup({
            documents: new FormArray([])
        });
    }
    DeviceFilesComponent.prototype.ngOnInit = function () {
        this.loadDocuments();
    };
    DeviceFilesComponent.prototype.onShowAddDocumentForOperator = function () {
        this.error = null;
        this.showAddDocumentsForm = true;
        WSLFormHelper.clearFormArray(this.form.get('documents'));
        this.addDocument();
        // (<FormArray>this.form.get('documents')).c
    };
    Object.defineProperty(DeviceFilesComponent.prototype, "docControls", {
        get: function () {
            return this.form.get('documents').controls;
        },
        enumerable: true,
        configurable: true
    });
    DeviceFilesComponent.prototype.onUploadDocuments = function () {
        var _this = this;
        var partFilledDocumnets = this.form.value.documents.filter(function (d) { return !d.type_object_id || !!d.type || !!d.fileURL; });
        var fullFilledDocuments = this.form.value.documents.filter(function (d) { return !!d.type_object_id && !!d.type && !!d.fileURL; });
        if (fullFilledDocuments.length === 0) {
            if (partFilledDocumnets.some(function (d) { return !d.type; })) {
                WSLMaterializeHelper.toast({ html: 'Задайте тип документа!' });
                return;
            }
            if (partFilledDocumnets.some(function (d) { return !d.type_object_id; })) {
                WSLMaterializeHelper.toast({ html: 'Задайте прибор!' });
                return;
            }
            if (partFilledDocumnets.some(function (d) { return !d.fileURL; })) {
                WSLMaterializeHelper.toast({ html: 'Задайте файл!' });
                return;
            }
        }
        var formData = new FormData();
        // formData.append('device_id', JSON.stringify(this.device.id));
        for (var i = 0; i < fullFilledDocuments.length; i++) {
            formData.append('file[]', fullFilledDocuments[i].file);
            formData.append('type[]', fullFilledDocuments[i].type);
            //    formData.append('name[]', fullFilledDocuments[i].name);
            formData.append('type_object_id[]', fullFilledDocuments[i].type_object_id);
        }
        this.loading = true;
        this.error = null;
        this.documentService.add(formData)
            .pipe(take(1), tap(function (res) {
            if (!res.error) {
                _this.showAddDocumentsForm = false;
                _this.loadDocuments();
            }
        }), catchError(function (e) {
            var error = WSLHttpHelper.parseHTTPErrorResponse(e);
            _this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
            return of(e);
        }), finalize(function () {
            _this.loading = false;
            _this.chr.markForCheck();
        })).subscribe();
    };
    DeviceFilesComponent.prototype.onCancelUploadDocuments = function () {
        WSLFormHelper.clearFormArray(this.form.get('documents'));
        this.showAddDocumentsForm = false;
        this.error = null;
    };
    DeviceFilesComponent.prototype.addDocument = function () {
        var fA = this.form.get('documents');
        if (fA.length === 0 || fA.value.every(function (fg) { return !!fg.type_object_id && !!fg.type && !!fg.fileURL; })) {
            fA.push(new FormGroup({
                type: new FormControl(null),
                type_object_id: new FormControl(null),
                //    name: new FormControl(''),
                file: new FormControl(null),
                fileURL: new FormControl(null),
                fileName: new FormControl(null),
                fileSize: new FormControl(null)
            }));
        }
    };
    DeviceFilesComponent.prototype.trackById = function (index, file) {
        return file.id;
    };
    DeviceFilesComponent.prototype.loadDocuments = function () {
        var _this = this;
        this.loading = true;
        this.error = null;
        this.documentService.getMany({ type_object_id: this.device.id, type: DeviceDocumentType.passportDevice })
            .pipe(take(1), tap(function (res) {
            if (!res.error) {
                _this.documents = res.items;
            }
            else {
            }
        }), catchError(function (e) {
            _this.documents = [];
            var error = WSLHttpHelper.parseHTTPErrorResponse(e);
            _this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
            return of(e);
        }), finalize(function () {
            _this.loading = false;
            _this.chr.markForCheck();
        })).subscribe();
    };
    DeviceFilesComponent.prototype.onDownloadFile = function (id) {
        var _this = this;
        this.loading = true;
        this.error = null;
        this.documentService.get(id)
            .pipe(take(1), tap(function (res) {
            var uid = 'doc' + WSLUtils.randomID();
            _this.elementRef.nativeElement.insertAdjacentHTML('beforeend', "<a id=\"" + uid + "\" class=\"wsl-hide\" href=\"" + res.url + "\" target=\"_blank\"></a>");
            setTimeout(function () {
                var el = _this.elementRef.nativeElement.querySelector('#' + uid);
                el.click();
                _this.elementRef.nativeElement.removeChild(el);
            }, 2);
        }), catchError(function (e) {
            var error = WSLHttpHelper.parseHTTPErrorResponse(e);
            _this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
            return of(e);
        }), finalize(function () {
            _this.loading = false;
            _this.chr.markForCheck();
        }))
            .subscribe();
    };
    DeviceFilesComponent.prototype.onDeleteFile = function (id) {
        var _this = this;
        this.loading = true;
        this.error = null;
        this.documentService.delete(id)
            .pipe(take(1), tap(function (res) {
            _this.loadDocuments();
        }), catchError(function (e) {
            var error = WSLHttpHelper.parseHTTPErrorResponse(e);
            _this.error = error.view ? WSLHttpHelper.parseAsyncMessage(error.message) : null;
            return of(e);
        }), finalize(function () {
            _this.loading = false;
            _this.chr.markForCheck();
        }))
            .subscribe();
    };
    return DeviceFilesComponent;
}());
export { DeviceFilesComponent };
