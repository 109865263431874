import {Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import {IWSLObject} from '@app/object/models/object';
import {IWSLAsyncErrors, WSLFormValidators} from 'wsl-core';
import {IWSLRoom} from '@app/room/models/room';
import {IWSLDevice} from 'wsl-device';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IWSLRoomFilter} from '@app/room/models/room-filter';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {WSLMaterializeHelper} from 'wsl-shared';

@Component({
  selector: 'wsl-add-device-select-location',
  templateUrl: './add-device-select-location.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceSelectLocationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() device: IWSLDevice;
  @Input() objects: IWSLObject[];
  @Input() objectPending: boolean;
  @Input() objectErrors: IWSLAsyncErrors;
  @Input() rooms: IWSLRoom[];
  @Input() roomPending: boolean;
  @Input() roomErrors: IWSLAsyncErrors;
  @Input() disabledRoom = true;
  @Output() loadRooms = new EventEmitter<IWSLRoomFilter>();
  @Output() clearRooms = new EventEmitter<null>();
  @Output() addRoom = new EventEmitter<IWSLRoom>();
  @Output() selectLocation = new EventEmitter<{object_id: number, room_id: number}>();

  form = new FormGroup({
    object_id: new FormControl(null, [Validators.required]),
    room_id: new FormControl({value: null, disabled: true})
  });

  formRoom = new FormGroup({
    id: new FormControl(null),
    object_id: new FormControl(null, [Validators.required]),
    name: new FormControl('', [Validators.required, Validators.maxLength(32)]),
    living_area: new FormControl(null, [WSLFormValidators.inputMeteringsValidator]),
    count_owner: new FormControl(null, [WSLFormValidators.positiveNumberValidator])
  });

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  object: IWSLObject = null;
  showAddRoom = false;

  constructor() { }

  ngOnInit() {
    if (this.device && this.device.individual) {
      this.form.get('room_id').setValidators([Validators.required]);
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.get('object_id').valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(250),
        filter(() => this.form.dirty && !!this.device),
        distinctUntilChanged(),
        tap(object_id => {
          this.form.get('room_id').setValue(null);
          if (!object_id) {
            if (this.form.get('room_id').enabled) {
              this.form.get('room_id').disable();
            }
            this.clearRooms.emit();
          } else {
            if (this.form.get('room_id').disabled) {
              this.form.get('room_id').enable();
            }
            this.loadRooms.emit(object_id);
          }
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rooms) {
      // this.rooms = this.rooms.filter(r => this.device.individual ? r.residential : !r.residential);
      if (this.showAddRoom) {
        this.showAddRoom = false;
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  onSelectLocation() {
    if (this.form.valid) {
      this.selectLocation.emit(this.form.value);
    } else {
      if (this.form.get('object_id').invalid) {
        WSLMaterializeHelper.toast({html: 'Выберите объект'});
      } else if (this.form.get('room_id').invalid) {
        WSLMaterializeHelper.toast({html: 'Выберите помещение'});
      }
    }
  }

  onShowAddRoom() {
    if (this.form.get('object_id').invalid) {
      this.object = null;
      return;
    }
    this.object = this.objects.find(o => o.id === this.form.get('object_id').value);
    this.showAddRoom = true;
    this.formRoom.reset();
    this.formRoom.get('object_id').setValue(this.form.get('object_id').value, {emitEvent: false});
    this.formRoom.markAsPristine();
    this.formRoom.markAsUntouched();
  }

  onAddRoom() {
    if (this.formRoom.dirty) {
      if (this.formRoom.valid) {
        this.addRoom.emit(this.formRoom.getRawValue());
      } else {
        if (this.formRoom.get('object_id').invalid) {
          WSLMaterializeHelper.toast({html: 'Укажите объект'});
        }
        if (this.formRoom.get('name').invalid) {
          WSLMaterializeHelper.toast({html: 'Укажите название помещения'});
        }
      }
    } else {
      this.showAddRoom = false;
    }
  }


  onCancelAddRoom() {
    this.showAddRoom = false;
  }
}
