export const environment = {
  production: false,
  hmr: false,
  dom: false,
  kv: false,
  home: true,
  link: '/',
  absoluteLink: 'https://karat-wp.ws-lab.ru',
  homeLink: 'https://karat-wp.ws-lab.ru',
  hubCrossStorageLink: 'https://karat-b2b.ws-lab.ru'
};
