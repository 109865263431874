import {Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, Input} from '@angular/core';
import {IWSLDevice} from 'wsl-device';

@Component({
  selector: 'wsl-add-device-barcode-input',
  templateUrl: './add-device-barcode-input.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDeviceBarcodeInputComponent implements OnInit {
  @Input() device: IWSLDevice;
  @Input() disabled = true;

  @Output() barcodeInput = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onBarcodeInput(barcode: string) {
    this.barcodeInput.emit(barcode);
  }
}
